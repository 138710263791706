import React from 'react';
import { Grid, Typography } from '@material-ui/core';

interface VerticalSplitProps {
    title?: string;
    left: JSX.Element;
    right: JSX.Element;
}

const VerticalSplit = (props: VerticalSplitProps): JSX.Element => {
    let titleSection;
    if (props.title) {
        titleSection = (
            <Grid container>
                <Grid item md={9} xs={12}>
                    <Typography variant="h1" gutterBottom>
                        {props.title}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container>
            {titleSection}
            <Grid container spacing={3}>
                <Grid item md={9} xs={12}>
                    {props.left}
                </Grid>
                <Grid item md={3} xs={12} data-testid="info-section">
                    {props.right}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default VerticalSplit;
