import React, { Dispatch, SetStateAction, useContext } from 'react';

const WithBackdropContext = <T extends object, P extends object>(
    type: 'modal' | 'backdrop',
    context: React.Context<{
        options: T;
        setOptions: Dispatch<SetStateAction<T>>;
    }>,
    Component: React.ComponentType<P>,
): React.FC<P> => {
    const ComponentWithBackdropContext = (props: P): JSX.Element => {
        const { options, setOptions } = useContext(context);

        return type === 'backdrop' ? (
            <Component {...(props as P)} backdropOptions={options} setBackdropOptions={setOptions} />
        ) : (
            <Component {...(props as P)} modalOptions={options} setModalOptions={setOptions} />
        );
    };

    return ComponentWithBackdropContext;
};

export default WithBackdropContext;
