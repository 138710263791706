import { Dispatch, SetStateAction, createContext } from 'react';
import { GlobalValue } from '../data_types/global-value';
import { MemberOrderResponse } from '../data_types/member-order';

export const MembershipContext = createContext({
    membershipInfo: null as MemberOrderResponse | null,
    setMembershipInfo: (null as unknown) as Dispatch<SetStateAction<MemberOrderResponse>>,
    countries: null as GlobalValue[] | null,
    setCountries: (null as unknown) as Dispatch<SetStateAction<GlobalValue[]>>,
    careerTypes: null as GlobalValue[] | null,
    setCareerTypes: (null as unknown) as Dispatch<SetStateAction<GlobalValue[]>>,
});
