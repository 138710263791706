/* eslint-disable react/no-unescaped-entities */
import { FormHelperText, Grid } from '@material-ui/core';
import React, { FormEvent } from 'react';
import WithFormContext from '../../hocs/WithFormContext/WithFormContext';
import WithController from '../../hocs/WithController/WithController';
import ArrowedButton from '../Common/ArrowedButton/ArrowedButton';
import BoxWithTitle from '../Common/BoxWithTitle/BoxWithTitle';
import Radio from '../Common/Radio/Radio';
import RadioGroup from '../Common/RadioGroup/RadioGroup';
import { GridStyle } from './AccountRecovery.style';
interface AccountRecoveryComponentProps {
    errors: boolean;
    onSubmit: (event: FormEvent<HTMLButtonElement>) => void;
}

const RadioGroupWithController = WithFormContext(WithController(RadioGroup));

const AccountRecoveryComponent = (props: AccountRecoveryComponentProps): JSX.Element => {
    const classes = GridStyle();

    return (
        <BoxWithTitle title="Recover your account">
            <form noValidate>
                <Grid
                    className={classes.root}
                    container
                    spacing={1}
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item>
                        <RadioGroupWithController id="action" name="action">
                            <Radio label="I don't remember my password" value="forgotPassword" />
                            <Radio label="I don't have access to my account" value="recoveryAttempt" />
                        </RadioGroupWithController>
                    </Grid>
                    <Grid item className="full-width">
                        <ArrowedButton onClick={props.onSubmit} text="Continue" />
                        {props.errors && (
                            <FormHelperText role="alert" error={true}>
                                Please select an option
                            </FormHelperText>
                        )}
                    </Grid>
                </Grid>
            </form>
        </BoxWithTitle>
    );
};

export default AccountRecoveryComponent;
