import React from 'react';
import { Table, TableBody, TableRow, TableCell, Typography } from '@material-ui/core';
import { formatNumber } from '../../../../utils/format-number';
import { TotalTableRowStyle } from './Table.style';

interface OrderTotalTableComponentProps {
    total: number;
}

const OrderTotalTableComponent = (props: OrderTotalTableComponentProps): JSX.Element => {
    const classes = TotalTableRowStyle();

    return (
        <Table>
            <TableBody>
                <TableRow classes={{ root: classes.root }}>
                    <TableCell>
                        <Typography variant="subtitle2">Total membership dues</Typography>
                    </TableCell>
                    <TableCell data-testid="order-total" align="right">
                        {formatNumber(props.total)}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default OrderTotalTableComponent;
