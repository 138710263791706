import React from 'react';
import { List } from '@material-ui/core';

interface MembershipSidebarProps {
    children: React.ReactNode[] | React.ReactNode;
}

const MembershipSidebar = (props: MembershipSidebarProps): JSX.Element => {
    const children = React.Children.toArray(props.children).filter(Boolean);

    return (
        <List>
            {React.Children.map(children, (child) => {
                return <>{child}</>;
            })}
        </List>
    );
};

export default MembershipSidebar;
