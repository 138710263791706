import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MembershipContext } from '../../../contexts/MembershipContext';
import { GlobalValue } from '../../../data_types/global-value';
import { Contact, MemberOrderResponse } from '../../../data_types/member-order';
import { UpdateContactRequest } from '../../../data_types/update-contact-request';
import { useExternalScript } from '../../../hooks/use-external-script';
import {
    CONTACT_STATUS,
    DEFAULT_MODAL_CONTENT,
    INVALID_SESSION_MODAL_CONTENT,
    LOCAL_STORAGE_DUES_ESTIMATOR_VALUES_KEY,
    MEMBERSHIP_TYPE_MAPPING,
    SELF_DESCRIBE_NEW_GRADUATE,
    SESSION_INVALID_ERROR,
} from '../../../utils/constants';
import { ContactFormDefaultValues, generateContactFormOptions } from '../../../utils/generate-contact-form-options';
import { generateContactFormSchema } from '../../../utils/generate-contact-form-schema';
import getMembershipInfo from '../../../utils/get-membership-info';
import updateContact from '../../../utils/update-contact';
import { DuesEstimatorData } from '../../DuesEstimator/DuesEstimator';
import ContactFormComponent from './FormComponent';
import { ModalAttributes } from 'data_types/modal';

export interface ContactFormProps {
    contact: Contact;
    countries: GlobalValue[];
    careerTypes: GlobalValue[];
    setMembershipInfo?: Dispatch<SetStateAction<MemberOrderResponse>>;
    setModalOptions?: Dispatch<SetStateAction<ModalAttributes>>;
    onSubmit?: () => void;
}

interface ContactFormData {
    [key: string]: string | Date | boolean | undefined;
    selfDescribe: string;
    country: string;
    careerType?: string;
    zipCode?: string;
    gradDate?: Date;
    licenseDate?: Date;
    addressStreet: string;
    addressStreetLine2?: string;
    addressCity: string;
    addressState: string;
    addressCountry: string;
    addressZipCode: string;
}

interface Address {
    street: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
}

const isDirty = (data: ContactFormData, defaultValues: ContactFormDefaultValues): boolean => {
    const hasDirtyValues: boolean = [
        'country',
        'careerType',
        'zipCode',
        'gradDate',
        'licenseDate',
        'addressStreet',
        'addressCity',
        'addressState',
        'addressZipCode',
        'addressCountry',
    ]
        .map((key) => data[key] !== undefined && data[key] !== defaultValues[key])
        .some((value) => value === true);

    return data.selfDescribe !== defaultValues.selfDescribe || hasDirtyValues;
};

const updateContactBody = (
    data: ContactFormData,
    contact: Contact,
    orderDoesntExists: boolean,
): UpdateContactRequest => {
    return {
        AIA_Membership_Country_Picklist__c: data.country,
        AIA_Career_Type__c: data.careerType || contact.careerType,
        AIA_Membership_Zip_Code_Assignment__c: data.zipCode || null,
        AIA_Join_SelfDescribe__c: data.selfDescribe,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        AIA_Membership_Type__c: MEMBERSHIP_TYPE_MAPPING[data.selfDescribe]!,
        AIA_Join_Grad_Date__c: data.gradDate ? data.gradDate.toISOString().slice(0, 10) : contact.joinGradDate,
        AIA_Join_License_Date__c: data.licenseDate
            ? data.licenseDate.toISOString().slice(0, 10)
            : contact.joinLicenseDate,
        AIA_Mailing_Address_Street__c: data.addressStreetLine2
            ? data.addressStreet + ' ' + data.addressStreetLine2
            : data.addressStreet,
        AIA_Mailing_Address_City__c: data.addressCity,
        AIA_Mailing_Address_State__c: data.addressState,
        AIA_Mailing_Address_Zip__c: data.addressZipCode,
        AIA_Mailing_Address_Country__c: data.addressCountry,
        bundle__c: orderDoesntExists.toString(),
    };
};

const getDefaultValues = (contact: Contact): ContactFormDefaultValues => {
    const duesEstimatorDefaultValues: DuesEstimatorData =
        !contact.joinSelfDescribe && JSON.parse(localStorage.getItem(LOCAL_STORAGE_DUES_ESTIMATOR_VALUES_KEY) || '{}');

    const selfDescribe = contact.joinSelfDescribe || duesEstimatorDefaultValues.selfDescribe || '';
    const defaultSelfDescribe =
        contact.membershipStatus === CONTACT_STATUS.TERMINATED && selfDescribe === SELF_DESCRIBE_NEW_GRADUATE
            ? ''
            : selfDescribe;

    return {
        selfDescribe: defaultSelfDescribe,
        country: '',
        careerType:
            contact.careerType && contact.careerType !== 'None Selected'
                ? contact.careerType
                : duesEstimatorDefaultValues.careerType || '',
        zipCode: '',
        gradDate: contact.joinGradDate || duesEstimatorDefaultValues.gradDate?.slice(0, 10) || '',
        licenseDate: contact.joinLicenseDate || duesEstimatorDefaultValues.licenseDate?.slice(0, 10) || '',
    };
};

const extractAddress = (address: google.maps.GeocoderAddressComponent[]): Address => {
    let street = '';
    let city = '';
    let state = '';
    let country = '';
    let zipCode = '';

    for (const component of address as google.maps.GeocoderAddressComponent[]) {
        const componentType = component.types[0];

        switch (componentType) {
            case 'street_number': {
                street = `${component.long_name} ${street}`;
                break;
            }

            case 'route': {
                street += component.short_name;
                break;
            }

            case 'postal_code': {
                zipCode = `${component.long_name}${zipCode}`;
                break;
            }

            case 'postal_code_suffix': {
                zipCode = `${zipCode}-${component.long_name}`;
                break;
            }

            case 'locality':
                city = component.long_name;
                break;

            case 'administrative_area_level_1': {
                state = component.short_name;
                break;
            }

            case 'country':
                country = component.long_name;
                break;
        }
    }
    return { street, city, state, country, zipCode };
};

const ContactForm = (props: ContactFormProps): JSX.Element | null => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const { membershipInfo, setMembershipInfo } = useContext(MembershipContext);
    const externalScript = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`;
    const state = useExternalScript(externalScript);

    let autocomplete: google.maps.places.Autocomplete | null = null;
    let address2Field: HTMLInputElement;

    const defaultValues = getDefaultValues(props.contact);

    const schema = generateContactFormSchema();
    const formOptions = generateContactFormOptions({
        resolver: schema,
        defaultValues,
    });

    const formMethods = useForm(formOptions);

    const handlePlaceSelect = (): void => {
        const addressObject = (autocomplete as google.maps.places.Autocomplete).getPlace();
        const address = addressObject.address_components;
        if (address) {
            const selectedAddress = extractAddress(address);
            formMethods.setValue('addressStreet', selectedAddress.street);
            formMethods.setValue('addressCity', selectedAddress.city);
            formMethods.setValue('addressState', selectedAddress.state);
            formMethods.setValue('addressZipCode', selectedAddress.zipCode);
            formMethods.setValue('addressCountry', selectedAddress.country);
        }
        address2Field.focus();
    };

    useEffect(() => {
        if (state === 'ready') {
            address2Field = document.getElementById('addressStreetLine2') as HTMLInputElement;
            const autocompleteInput = document.getElementById('autocomplete') as HTMLInputElement;
            autocomplete = new google.maps.places.Autocomplete(autocompleteInput, {});
            autocomplete.addListener('place_changed', handlePlaceSelect);
        }
    }, [state]);

    const onSubmitHandler = async (data: ContactFormData): Promise<void> => {
        try {
            setIsLoading(true);
            if (isDirty(data, defaultValues) || !membershipInfo?.order?.order) {
                await updateContact(
                    props.contact.id,
                    updateContactBody(data, props.contact, !membershipInfo?.order?.order),
                );
                const newMembershipInfo = await getMembershipInfo();
                setMembershipInfo(newMembershipInfo);
            }
            props.onSubmit?.();
        } catch (error) {
            setHasError(true);
            if (error === SESSION_INVALID_ERROR) {
                props.setModalOptions?.({ ...INVALID_SESSION_MODAL_CONTENT, isOpen: true });
            } else {
                props.setModalOptions?.({ ...DEFAULT_MODAL_CONTENT, isOpen: true });
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <FormProvider {...formMethods}>
            <ContactFormComponent
                isLoading={isLoading}
                hasError={hasError}
                countries={props.countries}
                careerTypes={props.careerTypes}
                membershipStatus={props.contact.membershipStatus}
                onSubmit={formMethods.handleSubmit(onSubmitHandler)}
            />
        </FormProvider>
    );
};

export default ContactForm;
