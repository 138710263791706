import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import WithFormContext from '../../../../hocs/WithFormContext/WithFormContext';
import WithErrorHelper from '../../../../hocs/WithErrorHelper/WithErrorHelper';
import Checkbox from '../../../Common/Checkbox/Checkbox';
import Tooltip from '../../../Common/Tooltip/Tooltip';
import { MEMBERSHIP_TYPE_ALLIED } from '../../../../utils/constants';

interface OrderConfirmLicenseCheckboxProps {
    membershipType?: string;
}

const CheckboxWithError = WithFormContext(WithErrorHelper(Checkbox));

export const labelStyle = makeStyles({
    root: {
        marginRight: '5px',
    },
});

const OrderConfirmLicenseCheckbox = (props: OrderConfirmLicenseCheckboxProps): JSX.Element | null => {
    const labelClasses = labelStyle();
    const show = props.membershipType === MEMBERSHIP_TYPE_ALLIED;

    const disclaimer = `Those with established professional reputations who are registered to practice their professions
            where such requirements exist, or persons who are employed outside of architectural practice but are involved
            in positions allied to the field of architecture. Individual Allied members may include engineers, planners,
            landscape architects, sculptors, muralists, artist, and others in government, education, journalism, manufacturing,
            industry and/or other fields allied to architecture.`;

    const label = (
        <>
            I attest that I meet the requirements for Allied Membership of the AIA.{' '}
            <Typography color="error" component="span" className={labelClasses.root}>
                *
            </Typography>
            <Tooltip title={disclaimer} />
        </>
    );

    return show ? (
        <>
            <CheckboxWithError
                data-testid="allied-professional-checkbox"
                id="confirmAlliedProfessional"
                name="confirmAlliedProfessional"
                label={label}
            />
        </>
    ) : null;
};

export default OrderConfirmLicenseCheckbox;
