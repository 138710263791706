import React from 'react';
import {
    AppBar,
    Container as MuiContainer,
    List as MuiList,
    ListItem,
    Divider as MuiDivider,
    Link,
    withStyles,
} from '@material-ui/core';

const List = withStyles((theme) => ({
    root: {
        'min-height': '71px',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'row',
        },
        '& .MuiListItem-root': {
            width: 'initial',
        },
    },
}))(MuiList);

const Divider = withStyles((theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            height: '22px',
            'margin-top': 'auto',
            'margin-bottom': 'auto',
            width: '1px',
        },
    },
}))(MuiDivider);

const Footer = (): JSX.Element | null => {
    const year = new Date().getFullYear();

    return (
        <AppBar elevation={0} component="footer" color={'secondary'} position="static">
            <MuiContainer maxWidth="lg">
                <List aria-label="contacts">
                    <ListItem>© {year} AIA</ListItem>
                    <Divider />
                    <ListItem>(800) AIA-3837</ListItem>
                    <Divider />
                    <ListItem>
                        <Link href="mailto:membersupport@aia.org" target="_top">
                            membersupport@aia.org
                        </Link>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <Link href="https://www.aia.org/pages/22691-privacy-policy" target="_blank">
                            Privacy
                        </Link>
                    </ListItem>
                </List>
            </MuiContainer>
        </AppBar>
    );
};

export default Footer;
