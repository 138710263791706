import { makeStyles } from '@material-ui/core';

export const BorderedBoxStyle = makeStyles({
    root: {
        padding: '16px',
    },
});

export const CaptionStyle = makeStyles({
    root: {
        padding: '10px 4px 10px 4px',
    },
});

export const ContainerStyle = makeStyles(({ breakpoints }) => ({
    root: {
        paddingTop: '16px',
        paddingBottom: '16px',
        '&> div': {
            margin: 0,
            '& fieldset': {
                width: '100%',
            },
            '& .MuiTextField-root': {
                width: '100%',
            },
            [breakpoints.down('xs')]: {
                width: '100%',
                '&> .MuiGrid-item': {
                    width: '100%',
                    padding: '4px 0',
                },
            },
        },
    },
}));

export const ErrorHelperStyle = makeStyles({
    root: {
        '& .Mui-error': {
            paddingLeft: '16px',
        },
    },
});
