import React, { ReactNode } from 'react';
import { RadioGroup as MuiRadioGroup } from '@material-ui/core';
import { RadioGroupProps as MuiRadioGroupProps } from '@material-ui/core/RadioGroup/RadioGroup';
import { UseFormMethods } from 'react-hook-form';

interface RadioGroupProps extends MuiRadioGroupProps {
    formMethods?: UseFormMethods;
    children?: ReactNode[] | ReactNode;
}

const RadioGroup = (props: RadioGroupProps): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, formMethods, ...radioGroupProps } = props;
    return <MuiRadioGroup {...radioGroupProps}>{children}</MuiRadioGroup>;
};

export default RadioGroup;
