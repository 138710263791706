import React, { cloneElement } from 'react';
import { Table, TableHead, TableRow, TableBody, TableCell, Typography } from '@material-ui/core';
import { TitledTableCellStyle } from './TitledTable.style';

interface TitledTableProps {
    items: object[];
    title: string;
    children?: JSX.Element;
}

const TitledTable = (props: TitledTableProps): JSX.Element | null => {
    const classes = TitledTableCellStyle();

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={100} classes={{ head: classes.head }}>
                        <Typography>{props.title}</Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.items.map((item) => {
                    return props.children ? cloneElement(props.children, item) : null;
                })}
            </TableBody>
        </Table>
    );
};

export default TitledTable;
