import { makeStyles } from '@material-ui/core';

export const HomeStyle = makeStyles(({ breakpoints }) => ({
    root: {
        margin: 'auto',
        [breakpoints.down('sm')]: {
            marginTop: '25px',
        },
        [breakpoints.up('sm')]: {
            marginTop: 'auto',
        },
    },
}));
