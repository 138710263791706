import React from 'react';
import { UseFormMethods, useWatch } from 'react-hook-form';
import { SUPPLEMENTAL_DUES_OPTIONS_DESIGNATE_MEMBER } from '../../../../utils/constants';
import OrderSuppDuesResponsibleComponent from './ResponsibleComponent';

interface OrderSuppDuesResponsibleProps {
    formMethods?: UseFormMethods;
}

const OrderSuppDuesResponsible = (props: OrderSuppDuesResponsibleProps): JSX.Element | null => {
    const supplementalDuesMemberType = useWatch({
        control: props.formMethods?.control,
        name: 'supplementalDuesMemberType',
        defaultValue: '',
    });

    const show = supplementalDuesMemberType === SUPPLEMENTAL_DUES_OPTIONS_DESIGNATE_MEMBER;

    return show ? <OrderSuppDuesResponsibleComponent /> : null;
};

export default OrderSuppDuesResponsible;
