import React from 'react';
import { ListItem, Typography } from '@material-ui/core';

interface InfoTileProps {
    title: string;
    divider?: boolean;
    children: JSX.Element;
}

const InfoTile = ({ title, divider, children }: InfoTileProps): JSX.Element => {
    return (
        <ListItem disableGutters={true} divider={divider}>
            <div>
                <Typography variant="h3">{title}</Typography>
                {children}
            </div>
        </ListItem>
    );
};

export default InfoTile;
