import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogActions, Typography } from '@material-ui/core';
import { ModalAttributes } from '../../../data_types/modal';
import { DEFAULT_MODAL_CONTENT } from '../../../utils/constants';
import { ModalStyle } from './Modal.style';
import ArrowedButton from '../ArrowedButton/ArrowedButton';
import PlainButton from '../PlainButton/PlainButton';

interface ModalProps {
    modalOptions?: ModalAttributes;
}

const Modal = (props: ModalProps): JSX.Element => {
    const classes = ModalStyle();
    const modalOptions = props.modalOptions || DEFAULT_MODAL_CONTENT;
    const [isOpen, setIsOpen] = useState(modalOptions.isOpen);
    const onCancel = (event: React.MouseEvent<HTMLButtonElement>): void => {
        if (modalOptions.onCancel) {
            modalOptions.onCancel(event);
        }
        setIsOpen(false);
    };

    useEffect(() => {
        setIsOpen(props.modalOptions?.isOpen || false);
    }, [props.modalOptions]);

    return (
        <Dialog
            className={classes.root}
            open={isOpen}
            onClose={onCancel}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle className="padded" disableTypography id="alert-dialog-slide-title">
                <Typography variant="h2">{modalOptions.title}</Typography>
            </DialogTitle>
            <DialogContent className="padded">
                <DialogContentText id="alert-dialog-slide-description">{modalOptions.content}</DialogContentText>
            </DialogContent>
            <DialogActions className="padded">
                {modalOptions.onCancel && (
                    <PlainButton
                        name="modalCancel"
                        text={modalOptions.cancelActionName || ''}
                        className="cancel"
                        onClick={onCancel}
                        color="primary"
                    />
                )}
                {modalOptions.onConfirm && (
                    <ArrowedButton
                        name="modalConfirm"
                        data-testid="modalConfirm"
                        text={modalOptions.confirmActionName || ''}
                        className="confirm"
                        onClick={modalOptions.onConfirm}
                        color="primary"
                    />
                )}
            </DialogActions>
        </Dialog>
    );
};

export default Modal;
