import React, { useEffect, useState } from 'react';
import { AppBar, Container, Grid, Link, makeStyles } from '@material-ui/core';
import logo from '../../../assets/images/logo.svg';
import { HeaderAppBarStyle, HeaderContainerStyle } from './Header.style';
import { Auth } from 'aws-amplify';

const whiteLink = makeStyles({
    root: {
        color: '#fff',
        cursor: 'pointer',
    },
});

const Header = (): JSX.Element => {
    const appBarClass = HeaderAppBarStyle();
    const containerClass = HeaderContainerStyle();
    const whiteLinkClass = whiteLink();

    const [user, setUser] = useState(undefined as undefined | null);
    const handleHeader = (): void => {
        if (user) {
            window.location.assign(process.env.REACT_APP_MY_ACCOUNT_URL as string);
        } else {
            Auth.federatedSignIn();
        }
    };

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((userData) => {
                setUser(userData);
            })
            .catch(() => {
                setUser(null);
            });
    }, []);

    return (
        <AppBar classes={{ root: appBarClass.root }} elevation={0} color="primary" position="sticky">
            <Container classes={{ root: containerClass.root }} maxWidth="lg">
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item>
                        <Link href="http://www.aia.org/">
                            <img src={logo} alt="The American Institute of Architects" />
                        </Link>
                    </Grid>
                    {user !== undefined && (
                        <Grid item>
                            <Link className={whiteLinkClass.root} onClick={handleHeader}>
                                {user ? 'My account' : 'Sign In'}
                            </Link>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </AppBar>
    );
};

export default Header;
