const EXCLUDED_ZIPCODES = [
    '66012',
    '66018',
    '66021',
    '66030',
    '66031',
    '66051',
    '66061',
    '66062',
    '66063',
    '66083',
    '66085',
    '66101',
    '66102',
    '66103',
    '66104',
    '66105',
    '66106',
    '66109',
    '66110',
    '66111',
    '66112',
    '66113',
    '66115',
    '66117',
    '66118',
    '66119',
    '66160',
    '66201',
    '66202',
    '66203',
    '66204',
    '66205',
    '66206',
    '66207',
    '66208',
    '66209',
    '66210',
    '66211',
    '66212',
    '66213',
    '66214',
    '66215',
    '66216',
    '66217',
    '66218',
    '66219',
    '66220',
    '66221',
    '66222',
    '66223',
    '66224',
    '66225',
    '66226',
    '66227',
    '66250',
    '66251',
    '66276',
    '66282',
    '66283',
    '66285',
    '66286',
];

const isZipCodePayable = (zipCode: string | null | undefined): boolean => {
    return !EXCLUDED_ZIPCODES.includes(zipCode || '');
};

export default isZipCodePayable;
