import { CircularProgress, Typography } from '@material-ui/core';
import React from 'react';

const OrderRedirectToCheckout = (): JSX.Element => {
    return (
        <>
            <CircularProgress color="inherit" />
            <Typography variant="h2" component="h2">
                Redirecting to checkout page...
            </Typography>
        </>
    );
};

export default OrderRedirectToCheckout;
