import React, { useEffect, useState } from 'react';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { FormProvider, useForm } from 'react-hook-form';
import getContactStatuses, { ContactStatus } from '../../utils/get-contact-statuses';
import LandingHomeComponent from './LandingHomeComponent';
import getUserEmail from '../../utils/get-preferred-email';
import useQuery from '../../hooks/use-query';

const requiredMessage = 'This information is required.';
const invalidEmailMessage = 'Email must be valid.';

const LandingHome = (): JSX.Element => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null as null | 'Found' | 'Not-Found');
    const [contactStatus, setContactStatus] = useState(null as null | ContactStatus);
    const [email, setEmail] = useState('' as string);
    const [wasPreferredEmailFetched, setWasPreferredEmailFetched] = useState(false);

    const formMethods = useForm({
        resolver: joiResolver(
            Joi.object({
                email: Joi.string()
                    .email({ tlds: { allow: false } })
                    .required(),
            }).prefs({
                messages: {
                    'string.empty': requiredMessage,
                    'string.email': invalidEmailMessage,
                },
            }),
        ),
    });

    const query = useQuery();
    useEffect(() => {
        const sfid = query.get('ck');
        if (sfid && !wasPreferredEmailFetched) {
            getUserEmail(sfid).then(({ email }) => {
                formMethods.setValue('email', email);
            });
            setWasPreferredEmailFetched(true);
        }
    }, [query, formMethods, wasPreferredEmailFetched]);

    const getContactStatus = async (data: { email: string }): Promise<void> => {
        try {
            setLoading(true);
            setEmail(data.email);

            const statuses = await getContactStatuses(data.email);
            if (!statuses.length) {
                setStatus('Not-Found');
            } else {
                setContactStatus(statuses[0]);
                setStatus('Found');
            }
        } catch (error) {
            formMethods.setError('email', {
                type: 'manual',
                message: 'Something went wrong. Please try again later.',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleBack = (): void => {
        setStatus(null);
    };

    return (
        <FormProvider {...formMethods}>
            <LandingHomeComponent
                status={status}
                email={email}
                contactStatus={contactStatus}
                loading={loading}
                onSubmit={formMethods.handleSubmit(getContactStatus)}
                onBack={handleBack}
            />
        </FormProvider>
    );
};

export default LandingHome;
