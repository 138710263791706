import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import WithFormContext from 'hocs/WithFormContext/WithFormContext';
import CheckboxComponent from 'components/Common/Checkbox/Checkbox';
import { formatNumber } from 'utils/format-number';
import { OptionalProductsItemCellStyle } from './Item.style';
import Disclaimer from 'components/Common/Disclaimer/Disclaimer';

interface OrderOptionalProductsTableItemComponentProps {
    id: string;
    name: string;
    price: number;
    label: string;
    disclaimer?: {
        message: string;
        note?: string;
    };
    onChange: () => void;
    checked: boolean;
    disabled?: boolean;
}

const Checkbox = WithFormContext(CheckboxComponent);

const OrderOptionalProductsTableItemComponent = (props: OrderOptionalProductsTableItemComponentProps): JSX.Element => {
    const cellClasses = OptionalProductsItemCellStyle();

    return (
        <TableRow key={props.id}>
            <TableCell classes={{ root: cellClasses.root }}>
                <Checkbox
                    name={props.name}
                    label={props.label}
                    onChange={(): void => props.onChange()}
                    checked={!!props.checked}
                    disabled={props.disabled}
                />
                {props.disclaimer ? (
                    <Disclaimer
                        grayedOut={props.disabled}
                        message={props.disclaimer.message}
                        note={props.disclaimer.note}
                    />
                ) : null}
            </TableCell>
            <TableCell classes={{ root: cellClasses.root }}>
                <span>{formatNumber(props.price)}</span>
            </TableCell>
        </TableRow>
    );
};

export default OrderOptionalProductsTableItemComponent;
