import React from 'react';
import { Link, List, ListItem, Typography } from '@material-ui/core';

const Help = (): JSX.Element => {
    return (
        <List>
            <ListItem disableGutters={true}>
                <Typography variant="body1">(800) 242 3837 (option 2)</Typography>
            </ListItem>
            <ListItem disableGutters={true}>
                <Typography variant="body1">
                    <Link href="mailto:membersupport@aia.org" target="_top">
                        membersupport@aia.org
                    </Link>
                </Typography>
            </ListItem>
        </List>
    );
};

export default Help;
