import React from 'react';
import { PackageItemsEntity } from '../../../../data_types/member-order';
import TitledTable from '../../../Common/TitledTable/TitledTable';
import OrderOptionalProductsTableItem from './Item/Item';
import { generateOptionalCheckboxState } from 'utils/generate-optional-checkbox-state';
import { useMembershipInfo } from 'hooks/use-membership-info';
import { filterByVisibleOptionalProducts } from 'utils/filterByVisibleOptionalProducts';

interface OrderOptionalProductsTableProps {
    optionalProducts?: PackageItemsEntity[] | null;
}
interface IterableProductInterface {
    key: string;
    id: string;
    name: string;
    price: number;
    label: string;
    checkedByDefault: boolean;
    disclaimer: React.ReactNode | undefined;
    disabled: boolean;
}

const OrderOptionalProductsTable = (props: OrderOptionalProductsTableProps): JSX.Element | null => {
    const membershipInfo = useMembershipInfo();

    const generateProductOptions = (item: PackageItemsEntity, itemIndex: number): IterableProductInterface => {
        const checkboxData = generateOptionalCheckboxState({
            productName: item.OrderApi__Display_Name__c,
            membershipInfo: membershipInfo.membershipInfo,
        });

        return {
            key: item.Id,
            id: item.Id,
            name: `additionalPackages[${itemIndex}].${item.Id}`,
            price: item.OrderApi__Price__c,
            label: item.OrderApi__Display_Name__c,
            checkedByDefault: checkboxData.checked,
            disclaimer: checkboxData.disclaimer,
            disabled: checkboxData.disabled,
        };
    };

    const optionalProducts = filterByVisibleOptionalProducts(
        props.optionalProducts,
    )?.map((item: PackageItemsEntity, index: number) => generateProductOptions(item, index));

    const show = optionalProducts && optionalProducts.length > 0;

    return show && optionalProducts ? (
        <TitledTable title="Add ons:" items={optionalProducts}>
            <OrderOptionalProductsTableItem />
        </TitledTable>
    ) : null;
};

export default OrderOptionalProductsTable;
