import React from 'react';
import { UseFormMethods, useWatch } from 'react-hook-form';
import { Link } from '@material-ui/core';
import WithFormContext from '../../../../hocs/WithFormContext/WithFormContext';
import Checkbox from '../../../Common/Checkbox/Checkbox';
import Tooltip from 'components/Common/Tooltip/Tooltip';
import Disclaimer from 'components/Common/Disclaimer/Disclaimer';

const FormCheckbox = WithFormContext(Checkbox);
interface OrderAutoRenewCheckboxProps {
    defaultSubscriptionPlanId?: string;
    userHasSubscriptionPlanAvailable?: boolean;
    formMethods?: UseFormMethods;
}

const OrderAutoRenewCheckbox = (props: OrderAutoRenewCheckboxProps): JSX.Element | null => {
    const subscriptionPlanId = useWatch({
        control: props.formMethods?.control,
        name: 'subscriptionPlanId',
        defaultValue: props.defaultSubscriptionPlanId,
    });
    const autoRenewIsChecked = useWatch({
        control: props.formMethods?.control,
        name: 'autoRenew',
        defaultValue: false,
    });
    const show = subscriptionPlanId === props.defaultSubscriptionPlanId;
    const tooltipText =
        'If you opt for autorenewal, you will not be able to enroll in the monthly installment program.  Each renewal cycle allows for only one payment program selection.';
    const label = (
        <>
            AIA Membership Autorenewal: I agree to enroll in AIA&#39;s Automatic Membership Dues Renewal Program. By
            selecting this option, I acknowledge and agree to the{' '}
            <Link
                href="https://www.aia.org/terms-conditions-membership-autorenewal#:~:text=Your%20auto%2Drenewal%20will%20process,on%20the%20next%20business%20day"
                target="_blank"
                rel="noopener noreferrer"
            >
                terms &amp; conditions
            </Link>{' '}
            of the program. {props.userHasSubscriptionPlanAvailable ? <Tooltip title={tooltipText} /> : null}
        </>
    );
    const disclaimerMessage = (
        <>
            Your autorenewal will be charged on December 31. You can cancel your enrollment at any time by logging in to
            My Account at{' '}
            <Link href="https://me.aia.org" target="_blank" rel="noopener noreferrer">
                me.aia.org
            </Link>
            . Select Membership &gt; Manage My Autorenewal &gt; Cancel. You may also cancel by contacting the Member
            Support Center at (800) 242-3837, option 2, or{' '}
            <Link href="mailto:membersupport@aia.org" target="_blank" rel="noopener noreferrer">
                membersupport@aia.org
            </Link>
            . The program&apos;s terms and conditions are available on our website{' '}
            <Link
                href="https://www.aia.org/terms-conditions-membership-autorenewal"
                target="_blank"
                rel="noopener noreferrer"
            >
                here
            </Link>
            .
        </>
    );

    return show ? (
        <>
            <FormCheckbox data-testid="auto-renew-checkbox" id="auto-renew" name="autoRenew" label={label} />
            {autoRenewIsChecked && <Disclaimer message={disclaimerMessage} />}
        </>
    ) : null;
};

export default OrderAutoRenewCheckbox;
