import React from 'react';
import { Paper, PaperProps } from '@material-ui/core';

interface PaymentLayoutProps extends PaperProps {
    children?: React.ReactNode[] | React.ReactNode;
}

const BorderedBox = (props: PaymentLayoutProps): JSX.Element => {
    const { children, ...paperProps } = props;
    return (
        <Paper variant="outlined" square {...paperProps} data-testid="bordered-box">
            {children}
        </Paper>
    );
};

export default BorderedBox;
