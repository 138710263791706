import React, { useState } from 'react';
import OrderSuppDuesTableItemComponent from './ItemComponent';

export interface OrderSuppDuesTableItemProps {
    keyValue?: string;
    name?: string;
    placeholder?: string;
    stateDues?: number;
    localDues?: number;
    total?: number;
}

const OrderSuppDuesTableItem = (props: OrderSuppDuesTableItemProps): JSX.Element => {
    const [total, setTotal] = useState(props.total || 0);

    const handleOnChange = (value: string): void => {
        const stateDues = props.stateDues || 0;
        const localDues = props.localDues || 0;
        const total = (+value || 0) * (stateDues + localDues);
        setTotal(total);
    };

    return (
        <OrderSuppDuesTableItemComponent
            keyValue={props.keyValue || ''}
            name={props.name || ''}
            placeholder={props.placeholder || ''}
            stateDues={props.stateDues}
            localDues={props.localDues}
            total={total}
            onChange={handleOnChange}
        />
    );
};

export default OrderSuppDuesTableItem;
