import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { FormControlLabel, Checkbox as MuiCheckbox } from '@material-ui/core';
import { CheckboxProps as MuiCheckboxProps } from '@material-ui/core/Checkbox/Checkbox';
import { CheckboxStyle } from './Checkbox.style';

interface CheckboxProps extends MuiCheckboxProps {
    formMethods?: UseFormMethods;
    label: JSX.Element | string;
}

const Checkbox = (props: CheckboxProps): JSX.Element => {
    const classes = CheckboxStyle();
    const { formMethods, label, ...inputProps } = props;

    return (
        <FormControlLabel
            control={
                <MuiCheckbox
                    classes={{ root: classes.root, checked: classes.checked }}
                    inputRef={formMethods?.register}
                    {...inputProps}
                />
            }
            label={label}
        />
    );
};

export default Checkbox;
