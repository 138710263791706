/* eslint-disable react/no-unescaped-entities */
import { Typography } from '@material-ui/core';
import React from 'react';
import redirectToJoin from '../../utils/redirect-to-join';
import ArrowedButton from '../Common/ArrowedButton/ArrowedButton';
import PlainButton from '../Common/PlainButton/PlainButton';
import LandingBox from './LandingBox';

const handleJoin = (): void => {
    redirectToJoin();
};

const LandingAccountNotFound = (props: { email: string; onBack: () => void }): JSX.Element => {
    return (
        <LandingBox title="Account not found">
            <>
                <Typography variant="h3" data-testid="text">
                    Are you new here?
                    <br />
                    <br />
                    If you are attempting to renew an existing membership or reactivate a former membership, go back and
                    enter the email associated with your account.
                    <br />
                    <br />
                    If you are joining AIA for the first time, create an account for <strong>{props.email}</strong>
                    <br />
                    <br />
                    If you have any questions, please contact: 800 242 3837 Option 2
                </Typography>
                <ArrowedButton className="action-button" text="Create an account" onClick={handleJoin} />
                <PlainButton text="Back" onClick={(): void => props.onBack()} />
            </>
        </LandingBox>
    );
};

export default LandingAccountNotFound;
