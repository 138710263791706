/* eslint-disable @typescript-eslint/no-explicit-any */
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { Resolver } from 'react-hook-form';

const requiredMessage = 'This information is required.';
const joiSchemaMessages = {
    'string.empty': requiredMessage,
};

export const generateAccountRecoverySchema = (): Resolver<Record<string, any>, object> => {
    return joiResolver(
        Joi.object({
            action: Joi.string().valid().required(),
        }).prefs({
            messages: joiSchemaMessages,
        }),
    );
};
