import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useWatch } from 'react-hook-form';
import countries from 'utils/help-country-list';

const CountryHelp = (): JSX.Element | null => {
    const selectedCountry = (useWatch({
        name: 'country',
    }) as unknown) as string;
    let helpText = '';
    Object.entries(countries).forEach(([, val]) => {
        if (val.value === selectedCountry) {
            helpText = val.text;
        }
    });

    return helpText ? (
        <Grid item xs={12}>
            <Typography variant="subtitle1" color="error">
                {helpText}
            </Typography>
        </Grid>
    ) : null;
};

export default CountryHelp;
