import React from 'react';
import { UseFormMethods, Controller } from 'react-hook-form';

type ControllerProps = {
    name: string;
    defaultValue?: string | number | undefined | null;
    formMethods?: UseFormMethods;
};

const WithController = <P extends object>(Component: React.ComponentType<P>): React.FC<P & ControllerProps> => {
    const ComponentWithController = ({ name, formMethods, ...props }: P & ControllerProps): JSX.Element => {
        return (
            <Controller control={formMethods?.control} name={name || 'default'} as={<Component {...(props as P)} />} />
        );
    };

    return ComponentWithController;
};

export default WithController;
