/* eslint-disable @typescript-eslint/no-explicit-any */
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import {
    MEMBERSHIP_COUNTRY_UNITED_STATES,
    SELF_DESCRIBE_ARCHITECT_DEGREE,
    SELF_DESCRIBE_FACULTY_MEMBER,
    SELF_DESCRIBE_NEW_GRADUATE,
    SELF_DESCRIBE_NON_US_ARCHITECT,
    SELF_DESCRIBE_US_ARCHITECT,
    SELF_DESCRIBE_ALLIED,
} from './constants';
import { Resolver } from 'react-hook-form';

const requiredMessage = 'This information is required.';
const noFutureDateMessage = 'Future dates are not valid.';
const joiSchemaMessages = {
    'string.base': requiredMessage,
    'string.empty': requiredMessage,
    'any.invalid': requiredMessage,
    'date.base': requiredMessage,
    'date.max': noFutureDateMessage,
};

const countryConditions = Joi.alternatives().conditional('selfDescribe', {
    is: SELF_DESCRIBE_ALLIED,
    then: Joi.string().allow('').empty(['']).default(MEMBERSHIP_COUNTRY_UNITED_STATES),
    otherwise: Joi.string().required(),
});

const usZipCodeValidation = {
    is: MEMBERSHIP_COUNTRY_UNITED_STATES,
    then: Joi.string()
        .pattern(/^[0-9]{5}(?:-[0-9]{4})?$/)
        .required()
        .messages({
            'string.pattern.base': 'It should be a valid US postal code.',
        }),
    otherwise: Joi.string(),
};

const zipCodeConditions = Joi.alternatives()
    .conditional('country', usZipCodeValidation)
    .when('selfDescribe', { is: SELF_DESCRIBE_ALLIED, then: Joi.forbidden() });

const addressZipCodeConditions = Joi.alternatives().conditional('addressCountry', usZipCodeValidation);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const selfDescribeCondition = (options: string[], isDateField?: boolean) => {
    return Joi.alternatives().conditional('selfDescribe', {
        is: Joi.string().valid(...options),
        then: isDateField ? Joi.date().max('now').required() : Joi.string().required(),
        otherwise: isDateField ? Joi.date().max('now').allow('') : Joi.string().allow(''),
    });
};

export const generateContactFormSchema = (addressRequired = true): Resolver<Record<string, any>, object> => {
    return joiResolver(
        Joi.object({
            selfDescribe: Joi.string().required(),
            country: countryConditions,
            zipCode: zipCodeConditions,
            careerType: selfDescribeCondition([
                SELF_DESCRIBE_US_ARCHITECT,
                SELF_DESCRIBE_NON_US_ARCHITECT,
                SELF_DESCRIBE_FACULTY_MEMBER,
            ]),
            gradDate: selfDescribeCondition([SELF_DESCRIBE_NEW_GRADUATE, SELF_DESCRIBE_ARCHITECT_DEGREE], true),
            licenseDate: selfDescribeCondition([SELF_DESCRIBE_US_ARCHITECT, SELF_DESCRIBE_NON_US_ARCHITECT], true),
            membershipStatus: Joi.string(),
            addressStreet: addressRequired ? Joi.string().required() : Joi.string(),
            addressStreetLine2: Joi.string().allow(''),
            addressCity: addressRequired ? Joi.string().required() : Joi.string(),
            addressState: addressRequired ? Joi.string().required() : Joi.string(),
            addressCountry: addressRequired ? Joi.string().required() : Joi.string(),
            addressZipCode: addressRequired ? addressZipCodeConditions : Joi.string(),
        }).prefs({
            messages: joiSchemaMessages,
        }),
    );
};
