/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import VerticalSplit from '../components/Common/VerticalSplit/VerticalSplit';
import DuesEstimator from '../components/DuesEstimator/DuesEstimator';
import { ModalContext } from '../contexts/ModalContext';
import WithBackdropContext from '../hocs/WithBackdropContext/WithBackdropContext';
import WithLoading from '../hocs/WithLoading/WithLoading';
import { useEntities } from '../hooks/use-entities';

const VerticalSplitWithLoading = WithLoading(VerticalSplit);
const DuesEstimatorWithModal = WithBackdropContext('modal', ModalContext, DuesEstimator);

const ScreensDuesEstimator = (): JSX.Element | null => {
    const { loading, error, fetchData, countries, careerTypes } = useEntities();

    useEffect(() => {
        if (!(countries && careerTypes)) {
            fetchData();
        }
    }, []);

    return (
        <VerticalSplitWithLoading
            loading={loading}
            error={error}
            title="Estimate membership dues"
            left={<DuesEstimatorWithModal />}
            right={<></>}
        />
    );
};

export default ScreensDuesEstimator;
