import React from 'react';
import { OrderDues } from '../../../../data_types/member-order';
import TitledTable from '../../../Common/TitledTable/TitledTable';
import OrderDuesTableItem from './Item/Item';

interface OrderDuesTableProps {
    dues?: OrderDues;
}

const OrderDuesTable = (props: OrderDuesTableProps): JSX.Element => {
    const chapterItems = [];
    if (props.dues) {
        chapterItems.push({ key: 'national', name: props.dues?.National.name, price: props.dues?.National.price });
        props.dues.State &&
            chapterItems.push({ key: 'state', name: props.dues?.State?.name, price: props.dues?.State?.price });
        props.dues.Local &&
            chapterItems.push({ key: 'local', name: props.dues?.Local?.name, price: props.dues?.Local?.price });
    }

    return (
        <TitledTable title="Dues:" items={chapterItems}>
            <OrderDuesTableItem />
        </TitledTable>
    );
};

export default OrderDuesTable;
