/* eslint-disable react/no-unescaped-entities */
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import BoxWithTitle from '../Common/BoxWithTitle/BoxWithTitle';
import SimpleForm from '../Common/SimpleForm/SimpleForm';
import { LandingBoxStyle } from './LandingBox.style';

const LandingBox = (props: { title: string; children?: JSX.Element | JSX.Element[] }): JSX.Element => {
    const classes = LandingBoxStyle();

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item>
                <BoxWithTitle title={props.title}>
                    <SimpleForm spacing={1} style={{ borderBottom: 'none' }}>
                        {props.children}
                    </SimpleForm>
                </BoxWithTitle>
            </Grid>
            <Grid item className={classes.footer}>
                <Typography variant="caption">
                    <Link
                        className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
                        to="/account-recovery"
                    >
                        Don't have access to your account?
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default LandingBox;
