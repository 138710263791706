import { makeStyles } from '@material-ui/core';

export const HeaderAppBarStyle = makeStyles(({ breakpoints }) => ({
    root: {
        height: '74px',
        '& img': {
            height: '70px',
        },
        [breakpoints.down('xs')]: {
            height: '54px',
            '& img': {
                height: '50px',
            },
        },
    },
}));

export const HeaderContainerStyle = makeStyles(({ breakpoints }) => ({
    root: {
        padding: '0 65px',
        [breakpoints.down('xs')]: {
            padding: '0 24px',
        },
    },
}));
