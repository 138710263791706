import React, { useContext } from 'react';
import { UseFormMethods, useWatch } from 'react-hook-form';
import { EmployeesTotal, OrderDues, StateOrLocal } from '../../../../data_types/member-order';
import {
    SUPPLEMENTAL_DUES_OPTIONS_SOLE_PRACTITIONER,
    SUPPLEMENTAL_DUES_OPTIONS_MANAGE_FIRM,
} from '../../../../utils/constants';
import calculateSupplementalDuesTotal from '../../../../utils/supplemental-dues-calculation';
import OrderTotalTableComponent from './TableComponent';
import { OrderContext } from 'contexts/OrderContext';

export interface OrderTotalTableProps {
    formMethods?: UseFormMethods;
    dues?: OrderDues;
    state?: StateOrLocal;
    local?: StateOrLocal;
}

const OrderTotalTable = (props: OrderTotalTableProps): JSX.Element | null => {
    let prices: (number | null | undefined)[] = [
        props.dues?.Local?.price,
        props.dues?.State?.price,
        props.dues?.National?.price,
    ];
    const { setOrderTotal } = useContext(OrderContext);
    const memberType: string =
        useWatch({
            control: props.formMethods?.control,
            name: 'supplementalDuesMemberType',
        }) || '';

    const employeesTotal: EmployeesTotal = {
        memberArchitectCount: useWatch({
            control: props.formMethods?.control,
            name: 'memberArchitectCount',
            defaultValue: 1,
        }),
        nonMemberArchitectCount: useWatch({
            control: props.formMethods?.control,
            name: 'nonMemberArchitectCount',
            defaultValue: null,
        }),
        associateCount: useWatch({
            control: props.formMethods?.control,
            name: 'associateCount',
            defaultValue: null,
        }),
        technicalStaffCount: useWatch({
            control: props.formMethods?.control,
            name: 'technicalStaffCount',
            defaultValue: null,
        }),
        otherStaffCount: useWatch({
            control: props.formMethods?.control,
            name: 'otherStaffCount',
            defaultValue: null,
        }),
    };

    const countEmployees = [
        SUPPLEMENTAL_DUES_OPTIONS_SOLE_PRACTITIONER,
        SUPPLEMENTAL_DUES_OPTIONS_MANAGE_FIRM,
    ].includes(memberType);
    const totalByEmployees = calculateSupplementalDuesTotal(employeesTotal, { State: props.state, Local: props.local });
    countEmployees && (prices = prices.concat(totalByEmployees.local + totalByEmployees.state));

    const total = prices.reduce((a: number, b) => a + (b || 0), 0);
    setOrderTotal(total);
    return <OrderTotalTableComponent total={total} />;
};

export default OrderTotalTable;
