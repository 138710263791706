import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { formatNumber } from '../../../../../utils/format-number';
import { DuesTableItemCellStyle } from './Item.style';

interface OrderDuesTableItemProps {
    name?: string;
    price?: number;
}

const OrderDuesTableItem = (props: OrderDuesTableItemProps): JSX.Element => {
    const classes = DuesTableItemCellStyle();

    return (
        <TableRow>
            <TableCell classes={{ root: classes.root }}>{props.name}</TableCell>
            <TableCell classes={{ root: classes.root }}>{formatNumber(props.price)}</TableCell>
        </TableRow>
    );
};

export default OrderDuesTableItem;
