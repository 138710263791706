import { makeStyles } from '@material-ui/core';

export const SuppDuesTableItemCellStyle = makeStyles({
    root: {
        padding: '5px 0px 5px 5px',
        verticalAlign: 'top',
        '&:first-of-type': {
            paddingLeft: '12px',
            '&> :first-child': {
                width: '100%',
                maxWidth: '300px',
                paddingBottom: '5px',
            },
        },
        '&:not(:first-child)': {
            paddingTop: '16px',
            textAlign: 'right',
        },
    },
});
