import React from 'react';
import { Tooltip as MaterialTooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { InfoIconStyle, TooltipStyle } from './Tooltip.style';

interface TooltipProps {
    title: string;
}

const Tooltip = (props: TooltipProps): JSX.Element => {
    const tooltipClasses = TooltipStyle();
    const infoIconClasses = InfoIconStyle();

    return (
        <MaterialTooltip title={props.title} classes={{ tooltip: tooltipClasses.tooltip }}>
            <InfoIcon classes={{ root: infoIconClasses.root }} aria-label="info" />
        </MaterialTooltip>
    );
};

export default Tooltip;
