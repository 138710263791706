import React from 'react';
import { Paper as MuiPaper, withStyles } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import theme from '../../../utils/theme';

interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
    message: string;
    hideIcon?: boolean;
}

const Paper = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '10px',
        color: theme.palette.error.main,
        border: 'none',
    },
})(MuiPaper);

const AlertIcon = withStyles({
    root: {
        fontSize: '2.5em',
        paddingRight: '10px',
    },
})(WarningIcon);

const Alert = (props: AlertProps): JSX.Element => {
    const { message, hideIcon, ...otherProps } = props;
    return (
        <Paper {...otherProps} variant="outlined">
            {!hideIcon && <AlertIcon />}
            {message}
        </Paper>
    );
};

export default Alert;
