import { makeStyles } from '@material-ui/core';

export const LandingStyle = makeStyles(() => ({
    form: {
        zIndex: 2,
    },
    box: {
        textAlign: 'center',
        '& fieldset.MuiFormControl-root, button': {
            width: '100%',
            padding: '16px 0',
        },
    },
}));
