import React, { ReactNode } from 'react';
import { FormControl, InputLabel, Select as MuiSelect } from '@material-ui/core';
import { SelectProps as MuiSelectProps } from '@material-ui/core/Select/Select';
import { SelectStyle } from './Select.style';

interface SelectProps extends MuiSelectProps {
    label: string;
    required?: boolean;
    children?: ReactNode[] | ReactNode;
}

const Select = (props: SelectProps): JSX.Element => {
    const selectClasses = SelectStyle();
    const { label, children, ...otherProps } = props;

    return (
        <FormControl className={selectClasses.root}>
            <InputLabel id={otherProps.name} shrink={true}>
                {label} {props.required && <span style={{ color: 'red' }}>*</span>}
            </InputLabel>
            <MuiSelect
                {...otherProps}
                inputProps={{
                    'aria-required': props.required,
                    'aria-invalid': props['aria-invalid'],
                    'aria-describedby': otherProps['aria-describedby'],
                }}
            >
                {children}
            </MuiSelect>
        </FormControl>
    );
};

export default Select;
