import React from 'react';
import { MemberOrderResponse } from '../../data_types/member-order';
import { Link, Typography } from '@material-ui/core';

interface ScreensActiveProps {
    membershipInfo?: MemberOrderResponse;
}

const getExpireDate = (membershipInfo: MemberOrderResponse | undefined): number | undefined => {
    return membershipInfo &&
        membershipInfo.contact &&
        membershipInfo.contact?.membershipExpireDate &&
        membershipInfo.contact?.membershipExpireDate !== ''
        ? new Date(membershipInfo.contact.membershipExpireDate).getFullYear()
        : undefined;
};

const ScreensActive = (props: ScreensActiveProps): JSX.Element => {
    const expireDate = getExpireDate(props.membershipInfo);

    return (
        <Typography variant="h2">
            <p>
                {expireDate
                    ? `According to our records, your membership is active and is due to expire on 12/31/${expireDate}. No further action is required at this time.`
                    : 'According to our records, your membership is active. No further action is required at this time.'}
            </p>
            <p>
                Should you like to verify this, please reach out to us by phone (800) 242 3837 (option 2) or email us at{' '}
                <Link href="mailto:membersupport@aia.org">membersupport@aia.org</Link>
            </p>
        </Typography>
    );
};

export default ScreensActive;
