import React from 'react';
import { UseFormMethods, useWatch } from 'react-hook-form';
import { TableCell, TableRow } from '@material-ui/core';
import { Chapters } from '../../../../data_types/member-order';
import OrderSuppDuesTableItem, { OrderSuppDuesTableItemProps } from './Item/Item';
import TitledTable from '../../../Common/TitledTable/TitledTable';
import OrderSuppDuesTableComponent from './TableComponent';
import {
    EMPLOYEES_INFO,
    SUPPLEMENTAL_DUES_OPTIONS_MANAGE_FIRM,
    SUPPLEMENTAL_DUES_OPTIONS_SOLE_PRACTITIONER,
} from '../../../../utils/constants';

interface OrderSuppDuesTableProps {
    formMethods?: UseFormMethods;
    chapters?: Chapters;
}

const OrderSuppDuesTable = (props: OrderSuppDuesTableProps): JSX.Element | null => {
    const supplementalDuesMemberType = useWatch({
        control: props.formMethods?.control,
        name: 'supplementalDuesMemberType',
        defaultValue: '',
    });

    const state = props.chapters?.State;
    const local = props.chapters?.Local;
    const show =
        supplementalDuesMemberType === SUPPLEMENTAL_DUES_OPTIONS_SOLE_PRACTITIONER ||
        supplementalDuesMemberType === SUPPLEMENTAL_DUES_OPTIONS_MANAGE_FIRM;

    const employeeTypesWithSupplementalDues = (): OrderSuppDuesTableItemProps[] => {
        const employeeTypes: OrderSuppDuesTableItemProps[] = [];

        Object.keys(EMPLOYEES_INFO).forEach((key) => {
            const stateDues = (state && (state[EMPLOYEES_INFO[key].rate] as number)) || 0;
            const localDues = (local && (local[EMPLOYEES_INFO[key].rate] as number)) || 0;

            if (stateDues + localDues > 0) {
                const row: OrderSuppDuesTableItemProps = {
                    keyValue: key,
                    name: key,
                    placeholder: EMPLOYEES_INFO[key].label,
                    stateDues: state?.AIA_Charge_Supplemental_dues__c ? stateDues : undefined,
                    localDues: local?.AIA_Charge_Supplemental_dues__c ? localDues : undefined,
                    total: key === 'memberArchitectCount' ? stateDues + localDues : 0,
                };
                employeeTypes.push(row);
            }
        });

        return employeeTypes;
    };

    const supplementalDuesItems = employeeTypesWithSupplementalDues();

    return show ? (
        <TitledTable title="Supplemental dues:" items={[{ key: 'orderSuppDuesTableRow' }]}>
            <TableRow>
                <TableCell>
                    <OrderSuppDuesTableComponent items={supplementalDuesItems}>
                        <OrderSuppDuesTableItem />
                    </OrderSuppDuesTableComponent>
                </TableCell>
            </TableRow>
        </TitledTable>
    ) : null;
};

export default OrderSuppDuesTable;
