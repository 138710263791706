import { API } from 'aws-amplify';
import { AccountRecoveryAttempt } from '../data_types/account-recovery-attempt';

const createAccountRecoveryAttempt = async (request: AccountRecoveryAttempt): Promise<void> => {
    return API.post('membership', '/account-recovery-attempt', {
        body: {
            ...request,
        },
    });
};

export default createAccountRecoveryAttempt;
