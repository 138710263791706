import { OrderData } from '../components/Order/Order';
import {
    MemberOrderResponse,
    UpdateMembershipInfoRequest,
    SupplementalDuePaid,
    SupplementalDueContact,
    LineItem,
    PackageItemsEntity,
    StateOrLocal,
} from '../data_types/member-order';
import { chargesSupplementalDues } from './charges-supplemental-dues';
import { SUPPLEMENTAL_DUES_OPTIONS_SOLE_PRACTITIONER, SUPPLEMENTAL_DUES_OPTIONS_MANAGE_FIRM } from './constants';
import calculateSupplementalDuesTotal, { SuppDuesTotal } from './supplemental-dues-calculation';

const calculateAdditionalPackagesItems = (
    additionalPackages: object[],
    packageItems?: PackageItemsEntity[] | null,
): LineItem[] => {
    const lineItems: LineItem[] = [];
    additionalPackages.forEach((item) => {
        const additionalPackage = item as { [key: string]: boolean };
        const id = Object.keys(additionalPackage)[0];
        const value = additionalPackage[id];

        if (value) {
            const packageItem = packageItems?.find((packageItem) => {
                return packageItem.Id === id;
            });

            lineItems.push({
                OrderApi__Item__c: packageItem?.Id,
                OrderApi__Sale_Price__c: packageItem?.OrderApi__Price__c,
            });
        }
    });
    return lineItems;
};

const calculateInstallmentFeesItems = (
    subscriptionPlanId: string | undefined,
    defaultSubscriptionId: string | undefined,
    packageItems?: PackageItemsEntity[] | null,
): LineItem[] => {
    const lineItems: LineItem[] = [];
    if (subscriptionPlanId && subscriptionPlanId !== defaultSubscriptionId) {
        const installmentFee = packageItems?.find((item) => item.OrderApi__Display_Name__c.startsWith('Installment'));
        installmentFee &&
            lineItems.push({
                OrderApi__Item__c: installmentFee?.Id || undefined,
                OrderApi__Sale_Price__c: installmentFee?.OrderApi__Price__c,
            });
    }
    return lineItems;
};

const getTotal = (data: OrderData, state?: StateOrLocal, local?: StateOrLocal): SuppDuesTotal => {
    return calculateSupplementalDuesTotal(
        {
            memberArchitectCount: data.memberArchitectCount,
            nonMemberArchitectCount: data.nonMemberArchitectCount,
            associateCount: data.associateCount,
            technicalStaffCount: data.technicalStaffCount,
            otherStaffCount: data.otherStaffCount,
        },
        { State: state, Local: local },
    );
};

const calculateSuppDuesItems = (
    suppDuesTotals: SuppDuesTotal,
    packageItems?: PackageItemsEntity[] | null,
): LineItem[] => {
    const lineItems: LineItem[] = [];
    const supplementalDues = packageItems?.filter((item) =>
        item.OrderApi__Display_Name__c.startsWith('Supplemental Dues -'),
    );
    supplementalDues?.forEach((supplementalDue) => {
        if (
            (supplementalDue.AIA_Chapter_Level__c === 'Local' && suppDuesTotals.local > 0) ||
            suppDuesTotals.state > 0
        ) {
            lineItems.push({
                OrderApi__Item__c: supplementalDue.Id,
                OrderApi__Sale_Price__c:
                    supplementalDue.AIA_Chapter_Level__c === 'Local' ? suppDuesTotals.local : suppDuesTotals.state,
                OrderApi__Price_Override__c: true,
            });
        }
    });
    return lineItems;
};

const paysSuppDues = (supplementalDuesMemberType: string | undefined): boolean => {
    return (
        supplementalDuesMemberType !== undefined &&
        [SUPPLEMENTAL_DUES_OPTIONS_SOLE_PRACTITIONER, SUPPLEMENTAL_DUES_OPTIONS_MANAGE_FIRM].includes(
            supplementalDuesMemberType,
        )
    );
};

const createSupplementalDuePay = (
    data: OrderData,
    total: number,
    accountId: string | undefined,
    renewYear: number | null | undefined,
): SupplementalDuePaid => ({
    AIA_Member_Architect_Count__c: data.memberArchitectCount,
    AIA_Non_member_Architect_Count__c: data.nonMemberArchitectCount,
    AIA_Associate_Count__c: data.associateCount,
    AIA_Technical_Staff_Count__c: data.technicalStaffCount,
    AIA_Other_Staff_Count__c: data.otherStaffCount,
    AIA_Total_Supplemental_Dues_Amount_Paid__c: total,
    AIA_Account__c: accountId,
    AIA_Supplemental_Dues_Year__c: renewYear || undefined,
});

const createSupplementalDueContact = (data: OrderData): SupplementalDueContact => ({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    AIA_Supp_Dues_Questionnaire_Response__c: data.supplementalDuesMemberType!,
    AIA_Supp_Dues_Member_ID__c: data.memberId,
    AIA_Supp_Dues_Name__c: data.memberName,
});

const createPayload = (
    data: OrderData,
    membershipInfo: MemberOrderResponse,
    defaultSubscriptionId: string | undefined,
): UpdateMembershipInfoRequest => {
    const additionalPackageItems = data.additionalPackages
        ? calculateAdditionalPackagesItems(data.additionalPackages, membershipInfo.related?.packageItems)
        : [];
    const installmentFeesItems = calculateInstallmentFeesItems(
        data.subscriptionPlanId,
        defaultSubscriptionId,
        membershipInfo.related?.packageItems,
    );

    let supplementalDuePaid: SupplementalDuePaid | undefined;
    let supplementalDueContact: SupplementalDueContact | undefined;
    let suppDuesItems: LineItem[] = [];

    if (chargesSupplementalDues(membershipInfo.chapters, membershipInfo.contact.emeritus)) {
        if (paysSuppDues(data.supplementalDuesMemberType)) {
            const suppDuesTotals = getTotal(data, membershipInfo.chapters?.State, membershipInfo.chapters?.Local);
            suppDuesItems = calculateSuppDuesItems(suppDuesTotals, membershipInfo.related?.packageItems);
            supplementalDuePaid = createSupplementalDuePay(
                data,
                suppDuesTotals.local + suppDuesTotals.state,
                membershipInfo.contact?.accountId,
                membershipInfo.contact?.renewYear,
            );
        }

        supplementalDueContact = createSupplementalDueContact(data);
    }

    return {
        OrderId: membershipInfo.order?.order?.Id || '',
        OrderApi__Subscription_Plan__c: data.subscriptionPlanId || defaultSubscriptionId,
        lineItems: additionalPackageItems.concat(installmentFeesItems, suppDuesItems),
        contact: supplementalDueContact,
        SupplementalDuePaid: supplementalDuePaid,
        AutoRenew: data.autoRenew || false,
    };
};

export default createPayload;
