import { Typography } from '@material-ui/core';
import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { UseFormMethods, useWatch } from 'react-hook-form';
import { filterByVisibleOptionalProducts } from 'utils/filterByVisibleOptionalProducts';
import { MemberOrderResponse, SubscriptionPlan } from '../../data_types/member-order';
import WithFormContext from '../../hocs/WithFormContext/WithFormContext';
import WithInsideLoading from '../../hocs/WithInsideLoading/WithInsideLoading';
import { calculateDuesTotal } from '../../utils/calculate-dues-total';
import { chargesSupplementalDues } from '../../utils/charges-supplemental-dues';
import ArrowedButton from '../Common/ArrowedButton/ArrowedButton';
import BorderedBox from '../Common/BorderedBox/BorderedBox';
import OneAtLeft from '../Common/OneAtLeft/OneAtLeft';
import OrderAdditionalFeesTable from './AdditionalFees/Table/Table';
import OrderAlert from './Alert/Alert';
import ArchiPACModal from './ArchiPACModal/ArchiPACModal';
import OrderAutoRenewCheckbox from './AutoRenew/Checkbox/Checkbox';
import OrderConfirmAlliedCheckbox from './ConfirmAllied/Checkbox/Checkbox';
import OrderConfirmLicenseCheckbox from './ConfirmLicense/Checkbox/Checkbox';
import OrderConfirmTermsCheckbox from './ConfirmTerms/Checkbox/Checkbox';
import OrderDuesTable from './Dues/Table/Table';
import OrderInstallmentsCheckbox from './Installments/Checkbox/Checkbox';
import OrderInstallmentsSelect from './Installments/Select/Select';
import OrderOptionalProductsTable from './OptionalProducts/Table/Table';
import { BorderedBoxStyle, ButtonRowStyle, MarginTopStyle } from './Order.style';
import OrderPayOfflineButtonComponent from './PayOffline/Button/Button';
import OrderSuppDuesMemberType from './SuppDues/MemberType/MemberType';
import OrderSuppDuesResponsible from './SuppDues/Responsible/Responsible';
import OrderSuppDuesTable from './SuppDues/Table/Table';
import OrderTotalTable from './Total/Table/Table';
import DisabledInstallment from './Installments/Disabled/DisabledInstallment';
import { OrderContext } from 'contexts/OrderContext';

export interface OrderComponentProps {
    membershipInfo: MemberOrderResponse;
    isLoading: boolean;
    hasError: boolean;
    paymentMethod: string;
    onSubmit?: (e?: BaseSyntheticEvent<object> | undefined) => Promise<void>;
    formMethods?: UseFormMethods;
}
const ARCHIPAC_DONATION = 'ArchiPAC donation';
const OrderPayOfflineButton = WithInsideLoading(OrderPayOfflineButtonComponent);
const OrderPayNowButton = WithInsideLoading(ArrowedButton);
const AlertWithFormContext = WithFormContext(OrderAlert);
const today = new Date();
const currentYear = today.getFullYear();
const currentMonth = today.getMonth() + 1;

const getDefaultSubscription = (subscriptionPlans: SubscriptionPlan[] | undefined): SubscriptionPlan | undefined => {
    return subscriptionPlans?.find((sub: SubscriptionPlan) => sub.OrderApi__Number_Of_Installments__c === null);
};

const handlePayment = (
    submitAction: 'online' | 'offline',
    e: React.FormEvent,
    onSubmit?: (e?: BaseSyntheticEvent<object> | undefined) => Promise<void>,
): void => {
    const event = { ...e, submitAction };
    onSubmit?.(event);
};

const getPaymentState = (
    paymentMethod: 'online' | 'offline',
    selectedPayment: string,
    isLoading: boolean,
): {
    loading: boolean;
    disabled: boolean;
} => {
    return {
        loading: selectedPayment === paymentMethod && isLoading,
        disabled: isLoading,
    };
};

const OrderComponent = (props: OrderComponentProps): JSX.Element => {
    const [showAutoRenewModal, setShowAutoRenewModal] = useState(false);
    const [orderTotal, setTotal] = useState<number | null>(null);
    const borderedBoxClasses = BorderedBoxStyle();
    const marginTopClasses = MarginTopStyle();
    const buttonRowClasses = ButtonRowStyle();
    const handlePayOnline = (e: React.FormEvent): void => handlePayment('online', e, props.onSubmit);
    const handlePayOffline = (e: React.FormEvent): void => handlePayment('offline', e, props.onSubmit);
    const payOnlineState = getPaymentState('online', props.paymentMethod, props.isLoading);
    const payOfflineState = getPaymentState('offline', props.paymentMethod, props.isLoading);

    const hasArchiPACDonationOption = props.membershipInfo.related?.packageItems?.some(
        (item) => item.OrderApi__Display_Name__c.toLowerCase() === ARCHIPAC_DONATION.toLowerCase(),
    );
    const getArchiPACCheckboxName = (): string => {
        if (hasArchiPACDonationOption) {
            const items = filterByVisibleOptionalProducts(props.membershipInfo.related?.packageItems);
            const itemIndex = items?.findIndex(
                (item) => item.OrderApi__Display_Name__c.toLowerCase() === ARCHIPAC_DONATION.toLowerCase(),
            ) as number;
            const itemId = items?.[itemIndex]?.Id;
            return `additionalPackages[${itemIndex}].${itemId}`;
        } else {
            return 'not-exist';
        }
    };
    const autoRenewIsChecked = useWatch({
        control: props.formMethods?.control,
        name: 'autoRenew',
        defaultValue: false,
    });
    const archiPACIsChecked = useWatch({
        control: props.formMethods?.control,
        name: getArchiPACCheckboxName(),
    });
    const isInstallmentsDisabled = process.env.REACT_APP_FEATURE_INSTALLMENTS_DISABLED === 'true';
    const userHasInstallmentsOption =
        props.membershipInfo.related?.subscriptionPlans &&
        props.membershipInfo.related?.subscriptionPlans.length > 1 &&
        calculateDuesTotal(props.membershipInfo.order?.items) > 0;
    const allowedInstallmentBasedOnMonth =
        !props.membershipInfo.contact?.renewYear ||
        (props.membershipInfo.contact?.renewYear === currentYear && currentMonth < 10) ||
        (props.membershipInfo.contact?.renewYear ?? 0) > currentYear;
    const hasAvailableInstallments = userHasInstallmentsOption && !autoRenewIsChecked && allowedInstallmentBasedOnMonth;
    const showSubscriptionPlans = isInstallmentsDisabled ? false : hasAvailableInstallments;
    const defaultSubscriptionPlanId = getDefaultSubscription(props.membershipInfo.related?.subscriptionPlans)?.Id;

    useEffect(() => {
        if (autoRenewIsChecked && archiPACIsChecked) {
            setShowAutoRenewModal(true);
        }
    }, [autoRenewIsChecked, archiPACIsChecked]);
    return (
        <OrderContext.Provider
            value={{
                orderTotal,
                setOrderTotal: setTotal,
            }}
        >
            <form noValidate>
                <AlertWithFormContext data-testid="error-alert" />
                {chargesSupplementalDues(
                    props.membershipInfo.chapters,
                    props.membershipInfo.contact?.emeritus,
                ) && (
                    <BorderedBox classes={{ root: borderedBoxClasses.root }}>
                        <Typography variant="h5" id="memberTypeLabel">
                            Your chapter assesses supplemental dues for membership. Please select the option that best
                            applies applies to you.{' '}
                            <Typography color="error" component="span">
                                *
                            </Typography>
                        </Typography>
                        <OrderSuppDuesMemberType />
                        <OrderSuppDuesResponsible />
                    </BorderedBox>
                )}
                {<Typography variant="h3">Order summary</Typography>}
                <OrderDuesTable dues={props.membershipInfo.order?.items} />
                <OrderSuppDuesTable chapters={props.membershipInfo.chapters} />
                <OrderTotalTable
                    dues={props.membershipInfo.order?.items}
                    state={props.membershipInfo.chapters?.State}
                    local={props.membershipInfo.chapters?.Local}
                />
                <OrderOptionalProductsTable optionalProducts={props.membershipInfo.related?.packageItems} />
                <OrderAdditionalFeesTable
                    defaultSubscriptionPlanId={defaultSubscriptionPlanId}
                    optionalProducts={props.membershipInfo.related?.packageItems}
                />

                <div className={marginTopClasses.root}>
                    <OrderConfirmLicenseCheckbox membershipType={props.membershipInfo.contact?.membershipType} />
                    <OrderConfirmTermsCheckbox />
                    <OrderConfirmAlliedCheckbox membershipType={props.membershipInfo.contact?.membershipType} />
                    {showSubscriptionPlans ? (
                        <OrderInstallmentsCheckbox defaultSubscriptionPlanId={defaultSubscriptionPlanId} />
                    ) : null}
                    {props.membershipInfo?.hasAccessToAutoRenew && !!orderTotal ? (
                        <div>
                            <OrderAutoRenewCheckbox
                                userHasSubscriptionPlanAvailable={showSubscriptionPlans}
                                defaultSubscriptionPlanId={defaultSubscriptionPlanId}
                            />
                            {hasArchiPACDonationOption ? (
                                <ArchiPACModal
                                    setShowAutoRenewModal={setShowAutoRenewModal}
                                    showAutoRenewModal={showAutoRenewModal}
                                />
                            ) : null}
                        </div>
                    ) : null}
                    {isInstallmentsDisabled && <DisabledInstallment />}
                </div>

                <div className={`${marginTopClasses.root} ${buttonRowClasses.root}`}>
                    <OneAtLeft alignItems="flex-end" className={buttonRowClasses.root}>
                        {!autoRenewIsChecked && (
                            <OrderPayOfflineButton
                                defaultSubscriptionPlanId={defaultSubscriptionPlanId}
                                {...payOfflineState}
                                color="secondary"
                                text="Download PDF Invoice"
                                onClick={handlePayOffline}
                            />
                        )}
                        {showSubscriptionPlans ? (
                            <OrderInstallmentsSelect
                                subscriptionPlans={props.membershipInfo.related?.subscriptionPlans}
                            />
                        ) : null}
                        <OrderPayNowButton {...payOnlineState} text="Pay now" onClick={handlePayOnline} />
                    </OneAtLeft>
                </div>
            </form>
        </OrderContext.Provider>
    );
};

export default OrderComponent;
