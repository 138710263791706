import { CustomFormOptions, FormOptions } from '../data_types/form-options';

interface RenewDefaultValues {
    subscriptionPlanId: string;
    supplementalDuesMemberType: string;
    memberName: string;
    memberId: string;
    memberArchitectCount: number;
    nonMemberArchitectCount: string;
    associateCount: string;
    technicalStaffCount: string;
    otherStaffCount: string;
    additionalPackages: string[];
    confirmLicense: boolean;
    confirmTerms: boolean;
    confirmInstallments: boolean;
}

export const generateRenewFormOptions = (options: CustomFormOptions<RenewDefaultValues>): FormOptions => {
    const defaultFormOptions = {
        resolver: options.resolver,
        defaultValues: {
            ...{
                subscriptionPlanId: '',
                supplementalDuesMemberType: '',
                memberName: '',
                memberId: '',
                memberArchitectCount: 1,
                nonMemberArchitectCount: '',
                associateCount: '',
                technicalStaffCount: '',
                otherStaffCount: '',
                additionalPackages: [],
                confirmLicense: false,
                confirmTerms: false,
                confirmInstallments: false,
                autoRenew: false,
            },
            ...options.defaultValues,
        },
        shouldFocusError: false,
    };
    return defaultFormOptions as FormOptions;
};
