import { Typography } from '@material-ui/core';
import React from 'react';
import { UseFormMethods, useWatch } from 'react-hook-form';
import Radio from '../../../../Common/Radio/Radio';
import { SubDescriptionStyle } from './Radio.style';

interface ContactFormSelfDescribeRadioProps {
    formMethods?: UseFormMethods;
    label: string;
    value: string;
    membershipStatus?: string;
    caption: string | (string | JSX.Element)[];
}

const ContactFormSelfDescribeRadio = (props: ContactFormSelfDescribeRadioProps): JSX.Element => {
    const subDescriptionClasses = SubDescriptionStyle();

    const selfDescribe = useWatch({
        control: props.formMethods?.control,
        name: 'selfDescribe',
        defaultValue: props.formMethods?.getValues('selfDescribe'),
    });

    return (
        <div key={props.label}>
            <Radio label={props.label} value={props.value} />
            {props.value === selfDescribe && (
                <div className={subDescriptionClasses.root}>
                    <Typography variant="caption">{props.caption}</Typography>
                </div>
            )}
        </div>
    );
};

export default ContactFormSelfDescribeRadio;
