import { Grid } from '@material-ui/core';
import React from 'react';
import { GridStyle } from './SimpleForm.style';

interface SimpleFormProps extends React.HTMLAttributes<HTMLDivElement> {
    spacing?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
}

const SimpleForm = (props: SimpleFormProps): JSX.Element => {
    const gridClasses = GridStyle();
    const { spacing, ...otherProps } = props;

    return (
        <Grid
            {...otherProps}
            className={gridClasses.root}
            container
            spacing={spacing || 3}
            direction="column"
            justify="center"
            alignItems="center"
        >
            {Array.isArray(props.children) ? (
                props.children.map((item, index) => (
                    <Grid key={`item-${index}`} item>
                        {item}
                    </Grid>
                ))
            ) : (
                <Grid item>{props.children}</Grid>
            )}
        </Grid>
    );
};

export default SimpleForm;
