import { makeStyles } from '@material-ui/core';

export const ModalStyle = makeStyles((theme) => ({
    root: {
        '& .MuiPaper-root': {
            borderRadius: '0px',
        },
        '& .MuiDialogTitle-root': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
        },
        '& .MuiDialogContent-root': {
            padding: '16px 24px',
            '& p': {
                color: '#000000',
                opacity: 0.87,
            },
        },
        '& .MuiDialogActions-root': {
            padding: '0 24px 16px 24px',
        },
        button: {
            '& .confirm': {
                backgroundColor: 'blue',
            },
            '& .cancel': {
                backgroundColor: 'yellow',
            },
        },
    },
}));
