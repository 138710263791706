import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

interface DisclaimerProps {
    grayedOut?: boolean;
    message: string | React.ReactElement;
    note?: string;
}

const cssStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'justify',
        textJustify: 'inter-word',
        marginTop: '12px',
    },
    grayedOut: {
        color: theme.palette.grey[500],
    },
}));

const Disclaimer = (props: DisclaimerProps): JSX.Element => {
    const styles = cssStyles();

    return (
        <div className={`${styles.root} ${props.grayedOut ? styles.grayedOut : ''}`}>
            {props.note ? (
                <>
                    <Typography variant="caption">Note: {props.note}</Typography>
                    <br /> <br />
                </>
            ) : null}
            <Typography variant="caption">{props.message}</Typography>
        </div>
    );
};

export default Disclaimer;
