import React from 'react';
import { MenuItem } from '@material-ui/core';
import { SubscriptionPlan } from '../../../../data_types/member-order';
import WithFormContext from '../../../../hocs/WithFormContext/WithFormContext';
import SelectComponent from '../../../Common/Select/Select';
import WithController from '../../../../hocs/WithController/WithController';
import { InstalmentsSelectStyle } from './Select.style';

interface OrderInstallmentsSelectProps {
    subscriptionPlans?: SubscriptionPlan[];
}

const Select = WithFormContext(WithController(SelectComponent));

const OrderInstallmentsSelect = (props: OrderInstallmentsSelectProps): JSX.Element | null => {
    const instalmentsSelectClasses = InstalmentsSelectStyle();

    const sortedSubscriptionPlans = props.subscriptionPlans?.sort(
        (a, b) => (a.OrderApi__Number_Of_Installments__c || 1) - (b.OrderApi__Number_Of_Installments__c || 1),
    );

    return (
        <Select
            data-testid="installments"
            id="subscriptionPlanId"
            name="subscriptionPlanId"
            label="Installments"
            className={instalmentsSelectClasses.root}
            defaultValue={sortedSubscriptionPlans?.[0].Id}
        >
            {sortedSubscriptionPlans?.map((subscription: SubscriptionPlan) => (
                <MenuItem key={subscription.Id} value={subscription.Id}>
                    {subscription.OrderApi__Number_Of_Installments__c || 'Pay in full'}
                </MenuItem>
            ))}
        </Select>
    );
};

export default OrderInstallmentsSelect;
