import { makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const BackgroundImageStyle = (background: string) =>
    makeStyles({
        root: {
            height: '100%',
        },
        backgroundImage: {
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
        },
        caption: {
            background: 'linear-gradient(0deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.5) 50%, rgba(255,255,255,0) 100%)',
            position: 'fixed',
            bottom: 0,
            '& .MuiGrid-container': {
                marginLeft: '40px',
                marginBottom: '10px',
                margin: '20px 0px 10px 40px',
            },
            zIndex: 1,
        },
    })();
