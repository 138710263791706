import React from 'react';
import { Typography } from '@material-ui/core';

const ScreensCesAudit = (): JSX.Element => {
    return (
        <Typography variant="h2">
            <p>
                Your record is under audit for not meeting AIA&apos;s education requirements, and are not eligible to
                renew till this has been corrected. Please contact member services at (800) AIA 3837.
            </p>
        </Typography>
    );
};

export default ScreensCesAudit;
