/* eslint-disable react/no-unescaped-entities */
import { InputLabel } from '@material-ui/core';
import React, { FormEvent } from 'react';
import WithErrorHelper from '../../../hocs/WithErrorHelper/WithErrorHelper';
import WithFormContext from '../../../hocs/WithFormContext/WithFormContext';
import WithInsideLoading from '../../../hocs/WithInsideLoading/WithInsideLoading';
import Input from '../../Common/Input/Input';
import ArrowedButton from '../../Common/ArrowedButton/ArrowedButton';
import DateInput from '../../Common/DateInput/DateInput';
import SimpleForm from '../../Common/SimpleForm/SimpleForm';
import PlainButton from '../../Common/PlainButton/PlainButton';

interface AccountRecoveryAttemptAdditionalInformationProps {
    onBack: (event: FormEvent<HTMLButtonElement>) => void;
    onSubmit: (event: FormEvent<HTMLButtonElement>) => void;
    show: boolean;
}

const InputWithError = WithFormContext(WithErrorHelper(Input));
const DateWithError = WithFormContext(WithErrorHelper(DateInput));
const ButtonWithLoading = WithInsideLoading(ArrowedButton);

const AccountRecoveryAttemptAdditionalInformation = (
    props: AccountRecoveryAttemptAdditionalInformationProps,
): JSX.Element => {
    return (
        <SimpleForm spacing={1} style={props.show ? {} : { display: 'none' }}>
            <InputLabel style={{ marginBottom: '12px' }}>
                Please enter as many fields as possible. This information will be used for verifying your request and
                its approval will depend on the correctness of your answers.
            </InputLabel>
            <DateWithError label="What's your birthdate?" id="birthdate" name="birthdate" required={true} />
            <InputWithError label="What year did you graduate?" id="degreeGraduationYear" name="degreeGraduationYear" />
            <InputWithError label="What university did you go to?" id="universityAttended" name="universityAttended" />
            <InputWithError
                label="Which state did you get your first license in?"
                id="stateFirstLicense"
                name="stateFirstLicense"
            />
            <InputWithError label="Which chapter did you last belong to?" id="previousChapter" name="previousChapter" />

            <div style={{ marginTop: '10px' }}>
                <ButtonWithLoading onClick={props.onSubmit} text="Next" />
                <PlainButton text="Go back" onClick={props.onBack} />
            </div>
        </SimpleForm>
    );
};

export default AccountRecoveryAttemptAdditionalInformation;
