import { makeStyles } from '@material-ui/core';

export const BorderedBoxStyle = makeStyles({
    root: {
        padding: '16px',
        marginBottom: '25px',
        '& > fieldset > .Mui-error': {
            paddingLeft: '16px',
        },
    },
});

export const MarginTopStyle = makeStyles({
    root: {
        marginTop: '30px',
    },
});

export const ButtonRowStyle = makeStyles(({ breakpoints }) => ({
    root: {
        [breakpoints.down('xs')]: {
            '&> :nth-child(2)': {
                order: -1,
            },
        },
    },
}));
