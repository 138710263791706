import React from 'react';
import { Chapters } from '../../../data_types/member-order';
import MembershipTaxes from '../../Membership/Taxes/Taxes';
import InfoTile from '../InfoTile/InfoTile';

interface TaxesTileProps {
    chapters?: Chapters;
}

const TaxesTile = (props: TaxesTileProps): JSX.Element | null => {
    const showTaxesTile =
        props.chapters &&
        (props.chapters.National?.Deductibility__c ||
            props.chapters.State?.Deductibility__c ||
            props.chapters.Local?.Deductibility__c);

    return props.chapters && showTaxesTile ? (
        <InfoTile title="Tax deductible percentages" divider={true}>
            <MembershipTaxes chapters={props.chapters} />
        </InfoTile>
    ) : null;
};

export default TaxesTile;
