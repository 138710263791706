import React from 'react';
import { Grid } from '@material-ui/core';
import WithFormContext from '../../../../hocs/WithFormContext/WithFormContext';
import WithErrorHelper from '../../../../hocs/WithErrorHelper/WithErrorHelper';
import InputComponent from '../../../Common/Input/Input';
import { MemberResponsibleStyle } from './Responsible.style';

const InputWithError = WithFormContext(WithErrorHelper(InputComponent));
const Input = WithFormContext(InputComponent);

const OrderSuppDuesResponsibleComponent = (): JSX.Element => {
    const gridClasses = MemberResponsibleStyle();

    return (
        <Grid data-testid="supp-dues-responsible" className={gridClasses.root} container direction="row" spacing={2}>
            <Grid item>
                <InputWithError required label="Member name" id="memberName" name="memberName" />
            </Grid>
            <Grid item>
                <Input label="Member number" id="memberId" name="memberId" />
            </Grid>
        </Grid>
    );
};

export default OrderSuppDuesResponsibleComponent;
