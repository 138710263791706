import React from 'react';
import { Typography } from '@material-ui/core';
import { UseFormMethods, useWatch } from 'react-hook-form';
import WithFormContext from '../../../../hocs/WithFormContext/WithFormContext';
import WithErrorHelper from '../../../../hocs/WithErrorHelper/WithErrorHelper';
import Checkbox from '../../../Common/Checkbox/Checkbox';

interface OrderInstallmentsCheckboxProps {
    defaultSubscriptionPlanId?: string;
    formMethods?: UseFormMethods;
}

const CheckboxWithError = WithFormContext(WithErrorHelper(Checkbox));

const OrderInstallmentsCheckbox = (props: OrderInstallmentsCheckboxProps): JSX.Element | null => {
    const subscriptionPlanId = useWatch({
        control: props.formMethods?.control,
        name: 'subscriptionPlanId',
        defaultValue: props.defaultSubscriptionPlanId,
    });

    const show = subscriptionPlanId !== props.defaultSubscriptionPlanId;

    const installmentsLabel = (
        <>
            By clicking the checkbox you agree to pay all installments until membership is paid in full. You will be
            charged today and the last day of each month. You must use a credit card for installment payments. Only
            membership dues can be paid in installments.{' '}
            <Typography color="error" component="span">
                *
            </Typography>
        </>
    );

    return show ? (
        <CheckboxWithError
            data-testid="installments-checkbox"
            id="confirmInstallments"
            name="confirmInstallments"
            label={installmentsLabel}
        />
    ) : null;
};

export default OrderInstallmentsCheckbox;
