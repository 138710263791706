import React, { useEffect, useState } from 'react';
import OrderOptionalProductsTableItemComponent from './ItemComponent';
interface OrderOptionalProductsTableItemProps {
    id?: string;
    name?: string;
    price?: number;
    label?: string;
    checkedByDefault?: boolean;
    disabled?: boolean;
    disclaimer?: {
        message: string;
        note?: string;
    };
}
const OrderOptionalProductsTableItem = (props: OrderOptionalProductsTableItemProps): JSX.Element => {
    const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);
    useEffect(() => {
        if (props.checkedByDefault) {
            setCheckboxChecked(true);
        }
    }, [props.checkedByDefault]);
    const handleCheck = (): void => setCheckboxChecked(!checkboxChecked);
    return (
        <OrderOptionalProductsTableItemComponent
            id={props.id || ''}
            name={props.name || ''}
            price={props.price || 0}
            label={props.label || ''}
            onChange={handleCheck}
            checked={checkboxChecked}
            disabled={props.disabled}
            disclaimer={props.disclaimer}
        />
    );
};
export default OrderOptionalProductsTableItem;
