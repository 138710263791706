import { API, Auth } from 'aws-amplify';
import { MemberOrderResponse } from '../data_types/member-order';

const getMembershipInfo = async (): Promise<MemberOrderResponse> => {
    const user = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    const response = await API.get('membership', '/membership-info', {
        queryStringParameters: {
            legacyId: user.attributes['custom:uuid'],
        },
        headers: {
            Authorization: currentSession.getAccessToken().getJwtToken(),
        },
    });

    return response.data;
};

export default getMembershipInfo;
