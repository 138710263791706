import { makeStyles } from '@material-ui/core';

export const DuesTableItemCellStyle = makeStyles({
    root: {
        padding: '12px 16px',
        '&:first-of-type': {
            paddingLeft: '27px',
        },
        '&:last-of-type': {
            textAlign: 'right',
        },
    },
});
