import { Stepper, Step, StepLabel, StepContent, StepButton } from '@material-ui/core';
import { AttachMoney, Check, Person } from '@material-ui/icons';
import React from 'react';
import VerticalSplit from '../components/Common/VerticalSplit/VerticalSplit';
import ContactForm from '../components/Contact/Form/Form';
import MembershipSidebar from '../components/Membership/Sidebar/Sidebar';
import Order from '../components/Order/Order';
import { GlobalValue } from '../data_types/global-value';
import { MemberOrderResponse } from '../data_types/member-order';
import clsx from 'clsx';
import { StepIconProps } from '@material-ui/core';
import { StepperIconStyle, StepperStyle, StepperLabelStyle, StepperContentStyle } from './Join.style';
import InfoTile from '../components/Common/InfoTile/InfoTile';
import Help from '../components/Common/Help/Help';
import Disclosures from '../components/Common/Disclosures/Disclosures';
import TaxesTile from '../components/Common/TaxesTile/TaxesTile';
import WithBackdropContext from '../hocs/WithBackdropContext/WithBackdropContext';
import { BackdropContext } from '../contexts/BackdropContext';
import { ModalContext } from '../contexts/ModalContext';
import { CONTACT_STATUS } from '../utils/constants';
import NonPayableZipCodeDisclaimer from '../components/NonPayableZipCodeDisclaimer/NonPayableZipCodeDisclaimer';
import isZipCodePayable from '../utils/is-zip-code-payable';

interface ScreensJoinProps {
    membershipInfo?: MemberOrderResponse;
    countries?: GlobalValue[];
    careerTypes?: GlobalValue[];
}

interface LeftSideProps {
    membershipInfo: MemberOrderResponse;
    countries: GlobalValue[];
    careerTypes: GlobalValue[];
}

interface StepsProps extends LeftSideProps {
    activeStep: number;
    handleStep: (step: number) => void;
}
interface RightSideProps {
    membershipInfo: MemberOrderResponse;
}

const ContactFormWithModal = WithBackdropContext('modal', ModalContext, ContactForm);
const OrderWithContext = WithBackdropContext(
    'backdrop',
    BackdropContext,
    WithBackdropContext('modal', ModalContext, Order),
);

const JoinStepperIcon = (props: StepIconProps): JSX.Element => {
    const { active, completed, icon } = props;
    const classes = StepperIconStyle();

    const stepperIcons: { [key: string]: JSX.Element } = {
        1: <Person />,
        2: <AttachMoney />,
    };

    const completedIcon = <Check />;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {completed ? completedIcon : stepperIcons[String(icon)]}
        </div>
    );
};

const JoinStepLabel = (label: string): JSX.Element => {
    const labelClasses = StepperLabelStyle();

    return (
        <StepLabel
            classes={{
                labelContainer: labelClasses.labelContainer,
                label: labelClasses.label,
            }}
            StepIconComponent={JoinStepperIcon}
        >
            {label}
        </StepLabel>
    );
};

const JoinStepContent = (content: JSX.Element): JSX.Element => {
    const contentClasses = StepperContentStyle();

    return <StepContent classes={{ transition: contentClasses.transition }}>{content}</StepContent>;
};

const Steps = (props: StepsProps): { label: string; content: JSX.Element }[] => {
    const showPaymentDisclaimer = !isZipCodePayable(props.membershipInfo.contact.membershipZipAssignment);

    return [
        {
            label: 'Primary information',
            content: (
                <ContactFormWithModal
                    contact={props.membershipInfo.contact}
                    countries={props.countries}
                    careerTypes={props.careerTypes}
                    onSubmit={(): void => props.handleStep(props.activeStep + 1)}
                />
            ),
        },
        {
            label: 'Order summary',
            content: showPaymentDisclaimer ? (
                <NonPayableZipCodeDisclaimer />
            ) : (
                <OrderWithContext membershipInfo={props.membershipInfo}/>
            ),
        },
    ];
};

const LeftSide = (props: LeftSideProps): JSX.Element | null => {
    const stepperClasses = StepperStyle();

    const [activeStep, setActiveStep] = React.useState(0);

    const handleStep = (stepIndex: number): void => {
        setActiveStep(stepIndex);
    };

    const steps = Steps({ ...props, activeStep, handleStep });

    return (
        <Stepper
            className={stepperClasses.root}
            activeStep={activeStep}
            orientation="vertical"
            style={{ width: '100%' }}
        >
            {steps.map((step, index) => {
                return (
                    <Step key={`step-${index}`}>
                        <StepButton onClick={(): void => handleStep(index)}>{JoinStepLabel(step.label)}</StepButton>
                        {JoinStepContent(step.content)}
                    </Step>
                );
            })}
        </Stepper>
    );
};

const RightSide = (props: RightSideProps): JSX.Element | null => {
    return (
        <MembershipSidebar>
            <TaxesTile chapters={props.membershipInfo.chapters} />
            <InfoTile title="Need help?" divider={true}>
                <Help />
            </InfoTile>
            <InfoTile title="Disclosures">
                <Disclosures />
            </InfoTile>
        </MembershipSidebar>
    );
};

const ScreensJoin = (props: ScreensJoinProps): JSX.Element | null => {
    if (!props.membershipInfo || !props.countries || !props.careerTypes) {
        return null;
    }

    const title =
        props.membershipInfo.contact.membershipStatus === CONTACT_STATUS.TERMINATED
            ? 'Restart your membership'
            : 'Welcome to AIA';

    return (
        <VerticalSplit
            title={title}
            left={
                <LeftSide
                    membershipInfo={props.membershipInfo}
                    countries={props.countries}
                    careerTypes={props.careerTypes}
                />
            }
            right={<RightSide membershipInfo={props.membershipInfo} />}
        />
    );
};

export default ScreensJoin;
