import React from 'react';
import WithErrorHelper from '../../../../hocs/WithErrorHelper/WithErrorHelper';
import WithFormContext from '../../../../hocs/WithFormContext/WithFormContext';
import {
    SUPPLEMENTAL_DUES_OPTIONS_DESIGNATE_MEMBER,
    SUPPLEMENTAL_DUES_OPTIONS_MANAGE_FIRM,
    SUPPLEMENTAL_DUES_OPTIONS_NON_SOLE_PRACTITIONER,
    SUPPLEMENTAL_DUES_OPTIONS_SOLE_PRACTITIONER,
} from '../../../../utils/constants';
import RadioComponent from '../../../Common/Radio/Radio';
import RadioGroup from '../../../Common/RadioGroup/RadioGroup';
import { MemberTypeRadioGroupStyle } from './MemberType.style';

const RadioGroupWithError = WithFormContext(WithErrorHelper(RadioGroup));
const Radio = WithFormContext(RadioComponent);

const SUPPLEMENTAL_DUES_OPTIONS = [
    {
        label: SUPPLEMENTAL_DUES_OPTIONS_NON_SOLE_PRACTITIONER,
        value: SUPPLEMENTAL_DUES_OPTIONS_NON_SOLE_PRACTITIONER,
    },
    {
        label: SUPPLEMENTAL_DUES_OPTIONS_DESIGNATE_MEMBER,
        value: SUPPLEMENTAL_DUES_OPTIONS_DESIGNATE_MEMBER,
    },
    {
        label: SUPPLEMENTAL_DUES_OPTIONS_SOLE_PRACTITIONER,
        value: SUPPLEMENTAL_DUES_OPTIONS_SOLE_PRACTITIONER,
    },
    {
        label: SUPPLEMENTAL_DUES_OPTIONS_MANAGE_FIRM,
        value: SUPPLEMENTAL_DUES_OPTIONS_MANAGE_FIRM,
    },
];

const OrderSuppDuesMemberType = (): JSX.Element => {
    const classes = MemberTypeRadioGroupStyle();
    return (
        <RadioGroupWithError
            data-testid="supp-dues-member-type"
            classes={{ root: classes.root }}
            id="supplementalDuesMemberType"
            name="supplementalDuesMemberType"
            aria-labelledby="memberTypeLabels"
        >
            {SUPPLEMENTAL_DUES_OPTIONS.map((option) => (
                <Radio key={option.label} {...option} />
            ))}
        </RadioGroupWithError>
    );
};

export default OrderSuppDuesMemberType;
