import React, { useRef } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { TextField, StandardTextFieldProps } from '@material-ui/core';
import { InputStyles } from './DateInput.styles';

interface InputProps extends StandardTextFieldProps {
    formMethods?: UseFormMethods;
    label: string;
}

const DateInput = (props: InputProps): JSX.Element => {
    const dateInputClasses = InputStyles();
    const textInput = useRef<HTMLDivElement>(null);

    const { formMethods, ...otherProps } = props;
    return (
        <TextField
            ref={textInput}
            type="date"
            InputLabelProps={{
                shrink: true,
            }}
            className={dateInputClasses.root}
            inputRef={formMethods?.register}
            {...otherProps}
            inputProps={{
                'aria-required': props.required,
                'aria-invalid': props['aria-invalid'],
                'aria-describedby': otherProps['aria-describedby'],
            }}
        />
    );
};

export default DateInput;
