import React from 'react';
import { Button, ButtonProps, makeStyles, Typography } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

export interface ArrowedButtonProps extends ButtonProps {
    text: string;
    arrowDirection?: 'left' | 'right';
}

const buttonClasses = makeStyles(({ breakpoints }) => ({
    root: {
        borderRadius: 0,
        textTransform: 'none',
        [breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

const ArrowedButton = (props: ArrowedButtonProps): JSX.Element => {
    const classes = buttonClasses();
    const { arrowDirection, text, color, className, ...otherProps } = props;

    return (
        <Button
            size="large"
            variant="contained"
            {...otherProps}
            className={`${classes.root} ${className}`}
            color={color || 'primary'}
            disableElevation
            startIcon={arrowDirection === 'left' && <KeyboardArrowLeftIcon />}
            endIcon={(!arrowDirection || arrowDirection === 'right') && <KeyboardArrowRightIcon />}
        >
            {props.children}
            <Typography>{text}</Typography>
        </Button>
    );
};

export default ArrowedButton;
