/* eslint-disable react/jsx-key */
import React from 'react';
import WithController from '../../../../hocs/WithController/WithController';
import WithErrorHelper from '../../../../hocs/WithErrorHelper/WithErrorHelper';
import WithFormContext from '../../../../hocs/WithFormContext/WithFormContext';
import RadioGroup from '../../../Common/RadioGroup/RadioGroup';
import { MembershipTypeRadioGroupStyle } from './MembershipType.style';
import {
    CONTACT_STATUS,
    JOIN_SELF_DESCRIBE_OPTIONS,
    MEMBERSHIP_TYPE_MAPPING,
    SELF_DESCRIBE_NEW_GRADUATE,
} from '../../../../utils/constants';
import ContactFormSelfDescribeRadio from '../SelfDescribe/Radio/Radio';

const RadioGroupWithError = WithFormContext(WithErrorHelper(WithController(RadioGroup)));

interface ContactFormMembershipType {
    hideNewGrad?: boolean;
    membershipStatus?: string;
}

const ContactFormMembershipType = (props: ContactFormMembershipType): JSX.Element => {
    const classes = MembershipTypeRadioGroupStyle();
    const options = props.hideNewGrad
        ? JOIN_SELF_DESCRIBE_OPTIONS.filter((option) => option.value !== SELF_DESCRIBE_NEW_GRADUATE)
        : JOIN_SELF_DESCRIBE_OPTIONS;

    return (
        <RadioGroupWithError
            data-testid="self-describe"
            classes={{ root: classes.root }}
            id="selfDescribe"
            name="selfDescribe"
            aria-labelledby="selfDescribeLabel"
        >
            {options.map((option, index) => {
                const caption = [
                    'You will be ',
                    props.membershipStatus === CONTACT_STATUS.TERMINATED ? 'restarting your membership' : 'joining',
                    ' as an ',
                    <b key={index}>{MEMBERSHIP_TYPE_MAPPING[option.value]}</b>,
                    ' member.',
                ];
                return (
                    <ContactFormSelfDescribeRadio
                        key={index}
                        label={option.label}
                        value={option.value}
                        caption={caption}
                        membershipStatus={props.membershipStatus}
                    />
                );
            })}
        </RadioGroupWithError>
    );
};

export default ContactFormMembershipType;
