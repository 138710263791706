import { MemberOrderResponse } from 'data_types/member-order';
import {
    ARCHIPAC_DONATION_DISCLAIMER,
    ARCHIPAC_DONATION_NOTE,
    MEMBERSHIP_TYPE_ASSOCIATE,
    MEMBERSHIP_TYPE_INTL_ASSOCIATE,
    ORLANDO_FOUNDATION_FOR_ARCHITECTURE_DISCLAIMER,
    PARTIAL_AIAU_SUBSCRIPTION_OPTIONAL_ITEM_NAME,
    SAN_FRANCISCO_SPECIAL_ASSESSMENT_DISCLAIMER,
    TAMPA_BAY_CENTER_FOR_ARCHITECTURE_DISCLAIMER,
} from 'utils/constants';

type OptionalProductCheckboxData = {
    checked: boolean;
    disclaimer?: {
        message: string;
        note?: string;
    };
    disabled: boolean;
};

type Props = {
    membershipInfo: MemberOrderResponse | null;
    productName: string;
};

export const generateOptionalCheckboxState = ({ membershipInfo, productName }: Props): OptionalProductCheckboxData => {
    const packageItems = membershipInfo?.related?.packageItems;
    const contactData = membershipInfo?.contact;

    let needToPrecheckDonationForUser;

    switch (productName) {
        case 'ArchiPAC donation':
            const storedValue = sessionStorage.getItem('isArchiPACPrechecked');
            const isPrechecked = storedValue ? JSON.parse(storedValue) : false;
            const isPacDonationActive = !!membershipInfo?.chapters?.State?.AIA_Require_PAC__c || isPrechecked;

            return {
                checked: isPacDonationActive,
                disclaimer: {
                    message: ARCHIPAC_DONATION_DISCLAIMER,
                    note: ARCHIPAC_DONATION_NOTE,
                },
                disabled: false,
            };
        case 'Orlando Foundation for Architecture':
            needToPrecheckDonationForUser = packageItems?.find((item) => item.OrderApi__Display_Name__c === productName)
                ?.AIA_Require_Item__c;

            return {
                checked: !!needToPrecheckDonationForUser,
                disclaimer: {
                    message: ORLANDO_FOUNDATION_FOR_ARCHITECTURE_DISCLAIMER,
                },
                disabled: false,
            };
        case 'Special Assessment for the Center for Architecture + Design':
            let canOptionalBeUnchecked = false;
            canOptionalBeUnchecked = [MEMBERSHIP_TYPE_ASSOCIATE, MEMBERSHIP_TYPE_INTL_ASSOCIATE].includes(
                contactData?.membershipType as string,
            );
            canOptionalBeUnchecked = canOptionalBeUnchecked || !!contactData?.emeritus;

            needToPrecheckDonationForUser = packageItems?.find((item) => item.OrderApi__Display_Name__c === productName)
                ?.AIA_Require_Item__c;

            needToPrecheckDonationForUser = needToPrecheckDonationForUser && !canOptionalBeUnchecked;
            return {
                checked: !!needToPrecheckDonationForUser,
                disclaimer: {
                    message: SAN_FRANCISCO_SPECIAL_ASSESSMENT_DISCLAIMER,
                },
                disabled: !canOptionalBeUnchecked,
            };
        case 'Tampa Bay Center for Architecture & Design':
            needToPrecheckDonationForUser = packageItems?.find((item) => item.OrderApi__Display_Name__c === productName)
                ?.AIA_Require_Item__c;
            return {
                checked: !!needToPrecheckDonationForUser,
                disclaimer: {
                    message: TAMPA_BAY_CENTER_FOR_ARCHITECTURE_DISCLAIMER,
                },
                disabled: false,
            };
        default:
            // Handle partial matches within the default case
            if (productName.includes(PARTIAL_AIAU_SUBSCRIPTION_OPTIONAL_ITEM_NAME)) {
                const storedValue = sessionStorage.getItem('isAiauPrechecked');
                const isPrechecked = storedValue ? JSON.parse(storedValue) : false;
                const disclaimerText = packageItems?.find((item) =>
                    item.OrderApi__Display_Name__c.includes(PARTIAL_AIAU_SUBSCRIPTION_OPTIONAL_ITEM_NAME),
                )?.AIA_Help_Text__c;
                return {
                    checked: isPrechecked,
                    disclaimer: disclaimerText
                        ? {
                              message: disclaimerText,
                          }
                        : undefined,
                    disabled: false,
                };
            }

            // Handle unknown cases
            return {
                checked: false,
                disclaimer: undefined,
                disabled: false,
            };
    }
};
