import { AppBar, Grid } from '@material-ui/core';
import React from 'react';
import background from '../../../assets/images/landing.jpg';
import { BackgroundImageStyle } from './BackgroundImage.style';

export interface BackgroundImageProps {
    children: React.ReactNode;
}

const BackgroundImage = (props: BackgroundImageProps): JSX.Element => {
    const classes = BackgroundImageStyle(background);

    return (
        <div className={`${classes.root} ${classes.backgroundImage}`}>
            {props.children}
            <AppBar elevation={0} component="footer" color={'secondary'} position="static" className={classes.caption}>
                <Grid style={{ color: '#ffff' }} direction="column" container>
                    <Grid item>
                        Conjunctive Points<span>&#8212;</span>The New City
                    </Grid>
                    <Grid item>Eric Owen Moss, FAIA</Grid>
                    <Grid item>Winner, AIA Twenty-five Year Award 2020</Grid>
                </Grid>
            </AppBar>
        </div>
    );
};

export default BackgroundImage;
