import { makeStyles } from '@material-ui/core';

export const TooltipStyle = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.dark,
        fontSize: theme.typography.caption.fontSize,
    },
}));

export const InfoIconStyle = makeStyles(() => ({
    root: {
        '& > svg': {
            margin: '10px',
        },
        fontSize: '20px',
        verticalAlign: 'text-bottom',
    },
}));
