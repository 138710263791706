import React, { memo, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import getDues from '../../utils/get-dues';
import OrderDuesTable from '../Order/Dues/Table/Table';
import OrderTotalTable from '../Order/Total/Table/Table';
import { Order } from '../../data_types/member-order';
import { MembershipContext } from '../../contexts/MembershipContext';
import { Grid, Typography } from '@material-ui/core';
import {
    DEFAULT_MODAL_CONTENT,
    DUES_ESTIMATOR_DISCLAIMER,
    INVALID_SESSION_MODAL_CONTENT,
    LOCAL_STORAGE_DUES_ESTIMATOR_VALUES_KEY,
    SESSION_INVALID_ERROR,
} from '../../utils/constants';
import ContactFormComponent, { ContactFormComponentProps } from '../Contact/Form/FormComponent';
import { generateContactFormSchema } from '../../utils/generate-contact-form-schema';
import { generateContactFormOptions } from '../../utils/generate-contact-form-options';
import ArrowedButton from '../Common/ArrowedButton/ArrowedButton';
import WithInsideLoading from '../../hocs/WithInsideLoading/WithInsideLoading';
import redirectToJoin from '../../utils/redirect-to-join';
import NonPayableZipCodeDisclaimer from '../NonPayableZipCodeDisclaimer/NonPayableZipCodeDisclaimer';
import isZipCodePayable from '../../utils/is-zip-code-payable';
import { OrderContext } from 'contexts/OrderContext';

interface DuesEstimatorProps {
    setModalOptions?: (opt: object) => void;
}
export interface DuesEstimatorData {
    selfDescribe: string;
    country: string;
    zipCode?: string;
    careerType?: string;
    gradDate?: string;
    licenseDate?: string;
    alternativeLicensePath?: boolean;
}

const contactFormPropsAreEqual = (prev: ContactFormComponentProps, next: ContactFormComponentProps): boolean => {
    return prev.hasError === next.hasError && prev.isLoading === next.isLoading;
};

const MemoizedContactForm = memo(ContactFormComponent, contactFormPropsAreEqual);

const ArrowedButtonWithLoading = WithInsideLoading(ArrowedButton);

const DuesEstimator = (props: DuesEstimatorProps): JSX.Element | null => {
    const [loadingDues, setLoadingDues] = useState(false);
    const [hasError, setHasError] = useState(false);
    const { countries, careerTypes } = useContext(MembershipContext);
    const [dues, setDues] = useState(null as Order | null);
    const [showPaymentDisclaimer, setShowPaymentDisclaimer] = useState(false);
    const [contactData, setContactData] = useState(null as DuesEstimatorData | null);
    const [loadingJoin, setLoadingJoin] = useState(false);
    const [orderTotal, setTotal] = useState<number | null>(null);

    const schema = generateContactFormSchema(false);
    const formOptions = generateContactFormOptions({
        resolver: schema,
    });

    const formMethods = useForm(formOptions);

    const onSubmitHandler = async (contactData: DuesEstimatorData): Promise<void> => {
        try {
            if (!isZipCodePayable(contactData.zipCode)) {
                setShowPaymentDisclaimer(true);
            } else {
                setLoadingDues(true);
                setShowPaymentDisclaimer(false);
                setContactData(contactData);
                const dues = await getDues(contactData);
                setDues(dues);
            }
            setTimeout(() => {
                window.scrollTo({ top: 1000, behavior: 'smooth' });
            }, 0);
        } catch (error) {
            setHasError(true);
            if (error === SESSION_INVALID_ERROR) {
                props.setModalOptions?.({ ...INVALID_SESSION_MODAL_CONTENT, isOpen: true });
            } else {
                props.setModalOptions?.({ ...DEFAULT_MODAL_CONTENT, isOpen: true });
            }
        } finally {
            setLoadingDues(false);
        }
    };

    const handleJoin = (): void => {
        setLoadingJoin(true);

        const prefillData = JSON.stringify(contactData);
        localStorage.setItem(LOCAL_STORAGE_DUES_ESTIMATOR_VALUES_KEY, prefillData);

        redirectToJoin();
    };

    return (
        <FormProvider {...formMethods}>
            <OrderContext.Provider
                value={{
                    orderTotal,
                    setOrderTotal: setTotal,
                }}
            >
                <MemoizedContactForm
                    isLoading={loadingDues}
                    hasError={hasError}
                    countries={countries || []}
                    careerTypes={careerTypes || []}
                    membershipStatus="Non-member"
                    isDuesEstimator={true}
                    onSubmit={formMethods.handleSubmit(onSubmitHandler)}
                />
                {dues && !loadingDues && (
                    <>
                        <Grid container spacing={3}>
                            <Grid container item>
                                <OrderDuesTable dues={dues.items} />
                                <OrderTotalTable dues={dues.items} />
                            </Grid>
                            <Grid container item>
                                <Typography style={{ textAlign: 'justify' }} variant="caption">
                                    {DUES_ESTIMATOR_DISCLAIMER}
                                </Typography>
                            </Grid>
                            <Grid container item direction="row" justify="flex-end" alignItems="center">
                                <ArrowedButtonWithLoading
                                    disabled={loadingJoin}
                                    loading={loadingJoin}
                                    onClick={handleJoin}
                                    name="join"
                                    text="Join AIA"
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
                {showPaymentDisclaimer && <NonPayableZipCodeDisclaimer />}
            </OrderContext.Provider>
        </FormProvider>
    );
};

export default DuesEstimator;
