import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { TextField, StandardTextFieldProps } from '@material-ui/core';

interface InputProps extends Omit<StandardTextFieldProps, 'variant'> {
    formMethods?: UseFormMethods;
    variant?: 'filled' | 'standard';
}

const Input = (props: InputProps): JSX.Element => {
    const { formMethods, ...otherProps } = props;
    return (
        <TextField
            inputRef={formMethods?.register}
            {...otherProps}
            inputProps={{
                'aria-required': props.required,
                'aria-invalid': props['aria-invalid'],
                'aria-describedby': otherProps['aria-describedby'],
            }}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
};

export default Input;
