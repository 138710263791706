import React from 'react';
import { Grid } from '@material-ui/core';
import { OneAtLeftStyle } from './OneAtLeft.style';

interface OneAtLeftProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode[] | React.ReactNode;
    alignItems?: 'flex-start' | 'center' | 'flex-end';
}

const OneAtLeft = (props: OneAtLeftProps): JSX.Element => {
    const classes = OneAtLeftStyle();

    return (
        <Grid
            container
            className={`${classes.root} ${props.className}`}
            direction="row"
            justify="space-between"
            data-testid="payment"
            spacing={2}
            alignItems={props.alignItems || 'flex-end'}
        >
            {Array.isArray(props.children)
                ? props.children?.map((item, index) => (
                      <Grid key={index} item>
                          {item}
                      </Grid>
                  ))
                : null}
        </Grid>
    );
};

export default OneAtLeft;
