import React, { ChangeEvent } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import WithFormContext from '../../../../../hocs/WithFormContext/WithFormContext';
import WithErrorHelper from '../../../../../hocs/WithErrorHelper/WithErrorHelper';
import Input from '../../../../Common/Input/Input';
import { formatNumber } from '../../../../../utils/format-number';
import { SuppDuesTableItemCellStyle } from './Item.style';

interface OrderSuppDuesTableItemComponentProps {
    keyValue: string;
    placeholder: string;
    name: string;
    stateDues?: number;
    localDues?: number;
    total: number;
    onChange: (value: string) => void;
}

const InputWithError = WithFormContext(WithErrorHelper(Input));

const OrderSuppDuesTableItemComponent = (props: OrderSuppDuesTableItemComponentProps): JSX.Element => {
    const classes = SuppDuesTableItemCellStyle();

    return (
        <TableRow key={props.keyValue}>
            <TableCell classes={{ root: classes.root }}>
                <InputWithError
                    id={props.name}
                    name={props.name}
                    required={true}
                    label={props.placeholder}
                    onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                        props.onChange(e.target.value);
                    }}
                />
            </TableCell>
            {props.stateDues !== undefined ? (
                <TableCell classes={{ root: classes.root }}>{formatNumber(props.stateDues)}</TableCell>
            ) : null}
            {props.localDues !== undefined ? (
                <TableCell classes={{ root: classes.root }}>{formatNumber(props.localDues)}</TableCell>
            ) : null}
            <TableCell classes={{ root: classes.root }} align="right">
                {formatNumber(props.total)}
            </TableCell>
        </TableRow>
    );
};

export default OrderSuppDuesTableItemComponent;
