import { Grid, MenuItem, Typography } from '@material-ui/core';
import React, { BaseSyntheticEvent } from 'react';
import { GlobalValue } from '../../../data_types/global-value';
import BorderedBox from '../../Common/BorderedBox/BorderedBox';
import ContactFormMembershipType from './MembershipType/MembershipType';
import ArrowedButton from '../../Common/ArrowedButton/ArrowedButton';
import WithInsideLoading from '../../../hocs/WithInsideLoading/WithInsideLoading';
import { BorderedBoxStyle, ErrorHelperStyle, ContainerStyle, CaptionStyle } from './Form.style';
import ConditionalFormPropertyRenderer from '../../Common/ConditionalFormPropertyRenderer/ConditionalFormPropertyRenderer';
import {
    MEMBERSHIP_COUNTRY_UNITED_STATES,
    SELF_DESCRIBE_ARCHITECT_DEGREE,
    SELF_DESCRIBE_AXP_ENROLLED,
    SELF_DESCRIBE_FACULTY_MEMBER,
    SELF_DESCRIBE_NEW_GRADUATE,
    SELF_DESCRIBE_NON_US_ARCHITECT,
    SELF_DESCRIBE_US_ARCHITECT,
    CONTACT_STATUS,
    SELF_DESCRIBE_SUPERVISION,
} from '../../../utils/constants';
import WithFormContext from '../../../hocs/WithFormContext/WithFormContext';
import WithErrorHelper from '../../../hocs/WithErrorHelper/WithErrorHelper';
import Input from '../../Common/Input/Input';
import DateInput from '../../Common/DateInput/DateInput';
import Select from '../../Common/Select/Select';
import WithController from '../../../hocs/WithController/WithController';
import { UseFormMethods, useWatch } from 'react-hook-form';
import RadioGroup from '../../Common/RadioGroup/RadioGroup';
import Radio from '../../Common/Radio/Radio';
import CountryHelp from './CountryHelp';

export interface ContactFormComponentProps {
    countries: GlobalValue[];
    careerTypes: GlobalValue[];
    isLoading: boolean;
    hasError: boolean;
    membershipStatus: string;
    onSubmit?: (e?: BaseSyntheticEvent<object> | undefined) => Promise<void>;
    isDuesEstimator?: boolean;
}

const ContactFormNextButton = WithInsideLoading(ArrowedButton);
const InputWithError = WithFormContext(WithErrorHelper(Input));
const DateWithError = WithFormContext(WithErrorHelper(DateInput));
const SelectWithError = WithFormContext(WithErrorHelper(WithController(Select)));
const RadioGroupWithError = WithFormContext(WithErrorHelper(WithController(RadioGroup)));

const NON_ALLIED = [
    SELF_DESCRIBE_US_ARCHITECT,
    SELF_DESCRIBE_NON_US_ARCHITECT,
    SELF_DESCRIBE_NEW_GRADUATE,
    SELF_DESCRIBE_AXP_ENROLLED,
    SELF_DESCRIBE_ARCHITECT_DEGREE,
    SELF_DESCRIBE_SUPERVISION,
    SELF_DESCRIBE_FACULTY_MEMBER,
];

const SelectWithErrorEntity = (props: {
    entities: GlobalValue[];
    name: string;
    label: string;
    describedBy?: string;
}): JSX.Element => {
    const { name, label, entities } = props;
    return (
        <SelectWithError
            id={name}
            name={name}
            label={label}
            defaultValue=""
            required={true}
            aria-describedby={props.describedBy}
        >
            {entities.map((entity) => (
                <MenuItem key={entity.id} value={entity.id}>
                    {entity.value}
                </MenuItem>
            ))}
        </SelectWithError>
    );
};

const CountryField = (props: { countries: GlobalValue[] }): JSX.Element => (
    <ConditionalFormPropertyRenderer formPropertyName="selfDescribe" showValues={NON_ALLIED}>
        <Grid item xs={12} sm={4}>
            <SelectWithErrorEntity
                entities={props.countries}
                name="country"
                label="Country"
                describedBy="countryAndZipCodeDescription"
            />
        </Grid>
        <CountryHelp />
    </ConditionalFormPropertyRenderer>
);

const ZipCodeField = (): JSX.Element => (
    <ConditionalFormPropertyRenderer formPropertyName="selfDescribe" showValues={NON_ALLIED}>
        <ConditionalFormPropertyRenderer formPropertyName="country" showValues={[MEMBERSHIP_COUNTRY_UNITED_STATES]}>
            <Grid item xs={12} sm={4}>
                <InputWithError
                    required={true}
                    id="zipCode"
                    name="zipCode"
                    label="Zip code"
                    aria-describedby="countryAndZipCodeDescription"
                />
            </Grid>
        </ConditionalFormPropertyRenderer>
    </ConditionalFormPropertyRenderer>
);

interface GradDateFieldProps {
    formMethods?: UseFormMethods;
}

const GradDateField = (props: GradDateFieldProps): JSX.Element => {
    const selfDescribe = useWatch({
        control: props.formMethods?.control,
        name: 'selfDescribe',
        defaultValue: props.formMethods?.getValues('selfDescribe'),
    });
    const required = selfDescribe !== SELF_DESCRIBE_AXP_ENROLLED;

    return (
        <ConditionalFormPropertyRenderer
            formPropertyName="selfDescribe"
            showValues={[SELF_DESCRIBE_NEW_GRADUATE, SELF_DESCRIBE_AXP_ENROLLED, SELF_DESCRIBE_ARCHITECT_DEGREE]}
        >
            <Grid item xs={12} sm={4}>
                <DateWithError required={required} id="gradDate" name="gradDate" label="Grad date" />
            </Grid>
        </ConditionalFormPropertyRenderer>
    );
};

interface AddressCustomFieldProps {
    name: string;
    label: string;
    required: boolean;
    [key: string]: string | Date | boolean | number | undefined;
}

const AddressCustomField = (props: AddressCustomFieldProps): JSX.Element => {
    const { name, label, required, ...otherProps } = props;

    return (
        <Grid item xs={12} sm={4} {...otherProps}>
            <InputWithError
                required={required}
                id={name}
                name={name}
                label={label}
                aria-describedby="addressDescription"
            />
        </Grid>
    );
};

const GradDateFieldWithFormContext = WithFormContext(GradDateField);

const LicenseDateField = (): JSX.Element => (
    <ConditionalFormPropertyRenderer
        formPropertyName="selfDescribe"
        showValues={[SELF_DESCRIBE_US_ARCHITECT, SELF_DESCRIBE_NON_US_ARCHITECT]}
    >
        <Grid item xs={12} sm={4}>
            <DateWithError required={true} id="licenseDate" name="licenseDate" label="Original license date" />
        </Grid>
    </ConditionalFormPropertyRenderer>
);

const CareerTypeField = (props: { careerTypes: GlobalValue[] }): JSX.Element => (
    <ConditionalFormPropertyRenderer
        formPropertyName="selfDescribe"
        showValues={[SELF_DESCRIBE_US_ARCHITECT, SELF_DESCRIBE_NON_US_ARCHITECT, SELF_DESCRIBE_FACULTY_MEMBER]}
    >
        <Grid item xs={12} sm={4}>
            <SelectWithErrorEntity entities={props.careerTypes} name="careerType" label="Career type" />
        </Grid>
    </ConditionalFormPropertyRenderer>
);

const ContactFormComponent = (props: ContactFormComponentProps): JSX.Element | null => {
    const borderedBoxClasses = BorderedBoxStyle();
    const errorHelperClasses = ErrorHelperStyle();
    const containerClasses = ContainerStyle();
    const captionClasses = CaptionStyle();

    const handleOnClick = (e: React.FormEvent): void => {
        props.onSubmit?.(e);
    };

    const hideNewGrad = props.membershipStatus === CONTACT_STATUS.TERMINATED;

    return (
        <>
            <Typography color="error" component="span">
                *
            </Typography>
            <Typography variant="caption"> Asterisks indicate required fields.</Typography>
            <form noValidate>
                <BorderedBox className={errorHelperClasses.root} classes={{ root: borderedBoxClasses.root }}>
                    {props.isDuesEstimator && (
                        <RadioGroupWithError
                            id="membershipStatus"
                            name="membershipStatus"
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignContent: 'center',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '5px',
                            }}
                        >
                            <Typography> I am a </Typography>
                            <Radio
                                label={<Typography style={{ fontWeight: 'bold' }}>new</Typography>}
                                value="Non-Member"
                                style={{ marginLeft: '0px', marginRight: '8px' }}
                            />
                            <Typography> or </Typography>
                            <Radio
                                label={<Typography style={{ fontWeight: 'bold' }}>former</Typography>}
                                value="Terminated"
                                style={{ marginLeft: '0px', marginRight: '8px' }}
                            />
                            <Typography>member.</Typography>
                        </RadioGroupWithError>
                    )}

                    <Typography variant="h5" id="selfDescribeLabel">
                        Choose the option that best describes you.{' '}
                        <Typography color="error" component="span">
                            *
                        </Typography>
                    </Typography>
                    <ContactFormMembershipType membershipStatus={props.membershipStatus} hideNewGrad={hideNewGrad} />
                </BorderedBox>

                <Grid container direction="column" className={containerClasses.root}>
                    <Grid container item alignItems="flex-start" spacing={1}>
                        <ConditionalFormPropertyRenderer formPropertyName="selfDescribe" showValues={NON_ALLIED}>
                            <Typography variant="h5" className={captionClasses.root} id="countryAndZipCodeDescription">
                                Chapter assignment is based on home or work address. Please provide the country and
                                postal code for either your home or work address to establish your chapter assignment.
                            </Typography>
                        </ConditionalFormPropertyRenderer>
                    </Grid>
                    <Grid container item alignItems="center" spacing={1}>
                        <CountryField countries={props.countries} />
                        <ZipCodeField />
                    </Grid>
                    <Grid container item alignItems="flex-start" spacing={1}>
                        <GradDateFieldWithFormContext />
                        <LicenseDateField />
                        <CareerTypeField careerTypes={props.careerTypes} />
                    </Grid>
                </Grid>

                {props.isDuesEstimator ? null : (
                    <Grid container direction="column" className={containerClasses.root}>
                        <Grid container item alignItems="flex-start" spacing={1}>
                            <Typography variant="h5" className={captionClasses.root} id="addressDescription">
                                Please share an address where we can send your new member kit.
                            </Typography>
                        </Grid>
                        <Grid container item alignItems="flex-start" spacing={1}>
                            <Grid item xs={12} sm={8}>
                                <Input
                                    id="autocomplete"
                                    name="autocomplete"
                                    label="Search for your address"
                                    placeholder=""
                                />
                            </Grid>
                        </Grid>
                        <Grid container item alignItems="flex-start" spacing={1}>
                            <AddressCustomField xs={12} sm={4} label="Street address" name="addressStreet" required />
                            <AddressCustomField
                                label="Apartment, suit, etc. (optional)"
                                name="addressStreetLine2"
                                required={false}
                            />
                        </Grid>
                        <Grid container item alignItems="flex-start" spacing={1}>
                            <AddressCustomField label="City" name="addressCity" required />
                            <AddressCustomField label="State" name="addressState" required />
                        </Grid>
                        <Grid container item alignItems="flex-start" spacing={1}>
                            <AddressCustomField label="Country" name="addressCountry" required />
                            <AddressCustomField label="Zip Code" name="addressZipCode" required />
                        </Grid>
                    </Grid>
                )}

                <Grid container direction="row" justify="flex-end" alignItems="center">
                    <ContactFormNextButton
                        text="Next"
                        loading={props.isLoading}
                        disabled={props.isLoading}
                        onClick={handleOnClick}
                    />
                </Grid>
            </form>
        </>
    );
};

export default ContactFormComponent;
