import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { generateRecoveryAttemptFormSchema } from '../../../utils/generate-recovery-attempt-form-schema';
import AccountRecoveryAttemptBasicInformation from './AttemptBasicInformation';
import AccountRecoveryAttemptAdditionalInformation from './AttemptAdditionalInformation';
import AccountRecoveryAttemptConfirmEmail from './AttemptConfirmEmail';
import { AccountRecoveryAttempt as AccountRecoveryAttemptType } from '../../../data_types/account-recovery-attempt';
import createAccountRecoveryAttempt from '../../../utils/create-account-recovery-attempt';
import { Step, StepIconProps, StepLabel, Stepper } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import clsx from 'clsx';
import { StepperIconStyle, StepperStyle } from './Attempt.style';

const StepperIcon = (props: StepIconProps): JSX.Element => {
    const { active, completed, icon, style } = props;
    const classes = StepperIconStyle();

    const completedIcon = <Check />;

    return (
        <div
            style={style}
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {completed ? completedIcon : icon}
        </div>
    );
};

const AccountRecoveryAttempt = (): JSX.Element => {
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1 as 1 | 2 | 3);
    const formMethods = useForm({
        resolver: generateRecoveryAttemptFormSchema(step),
    });

    const onSubmitHandler = async (data: AccountRecoveryAttemptType): Promise<void> => {
        try {
            setLoading(true);
            await createAccountRecoveryAttempt(data);
            setErrorMessage('');
            setSuccess(true);
        } catch (error) {
            const message =
                error?.response?.data?.message === 'Error:A contact with that email already exists'
                    ? 'A user with that email already exists. Try to reset your password instead or use a different recovery email.'
                    : 'Creating the recovery request failed. Please try again or call (800) AIA-3887.';

            setErrorMessage(message);
        } finally {
            setLoading(false);
        }
    };

    const stepperClass = StepperStyle();

    return (
        <div>
            <FormProvider {...formMethods}>
                {!success ? (
                    <Stepper
                        classes={{ horizontal: stepperClass.horizontal }}
                        alternativeLabel
                        nonLinear
                        activeStep={step - 1}
                    >
                        {[1, 2, 3].map((stepNumber) => {
                            return (
                                <Step completed={step > stepNumber} key={`step-${stepNumber}`}>
                                    <StepLabel StepIconComponent={StepperIcon}></StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                ) : (
                    <StepperIcon style={{ margin: '0 auto 20px auto' }} completed={true} active={true} icon={3} />
                )}
                <AccountRecoveryAttemptBasicInformation
                    show={step === 1}
                    onSubmit={formMethods.handleSubmit((): void => {
                        setStep(2);
                    })}
                />
                <AccountRecoveryAttemptAdditionalInformation
                    show={step === 2}
                    onBack={(): void => {
                        setStep(1);
                    }}
                    onSubmit={formMethods.handleSubmit((): void => {
                        setStep(3);
                    })}
                />
                <AccountRecoveryAttemptConfirmEmail
                    show={step === 3}
                    success={success}
                    loading={loading}
                    errorMessage={errorMessage}
                    onBack={(): void => {
                        setStep(2);
                    }}
                    onSubmit={formMethods.handleSubmit(onSubmitHandler)}
                />
            </FormProvider>
        </div>
    );
};

export default AccountRecoveryAttempt;
