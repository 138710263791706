import { generateState } from './generate-state';

const redirectToJoin = (): void => {
    const redirectUri = `${window.location.protocol}//${window.location.host}`;
    const clientId = process.env.REACT_APP_WEBCLIENT_ID;
    const scope = ['phone', 'email', 'profile', 'openid', process.env.REACT_APP_AUTH_MEMBERSHIP_SCOPE].join(' ');
    const state = generateState();

    window.location.assign(
        `https://${process.env.REACT_APP_AUTH_DOMAIN}/signup?redirect_uri=${redirectUri}&response_type=code&client_id=${clientId}&scope=${scope}&state=${state}`,
    );
};

export default redirectToJoin;
