import React from 'react';
import { useFormContext } from 'react-hook-form';

const WithFormContext = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => {
    const ComponentWithFormContext = (props: P): JSX.Element => {
        const methods = useFormContext();

        return <Component {...(props as P)} formMethods={methods} />;
    };

    return ComponentWithFormContext;
};

export default WithFormContext;
