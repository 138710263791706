import { Link, Typography } from '@material-ui/core';
import React from 'react';
import { DisclaimerStyle } from './NonPayableZipCodeDisclaimer.style';

const NonPayableZipCodeDisclaimer = (): JSX.Element => {
    const disclaimerClasses = DisclaimerStyle();

    return (
        <Typography data-testid="zip-disclaimer" className={disclaimerClasses.root} variant="h3">
            The zip code you have entered may provide you with alternative chapter membership options.{' '}
            <Link href="https://joinus.aia.org/johnson-wyandotte-counties/?_ga=2.91110617.259023110.1617110805-1465851210.1583865371">
                Learn more
            </Link>
        </Typography>
    );
};

export default NonPayableZipCodeDisclaimer;
