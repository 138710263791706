import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { CustomBackdropStyle } from './CustomBackdrop.style';

interface BackdropProps {
    backdropOptions?: { isOpen: boolean; child: JSX.Element; action?: Promise<void> };
}

const CustomBackdrop = (props: BackdropProps): JSX.Element | null => {
    const classes = CustomBackdropStyle();

    const [loading, setLoading] = useState(true);
    const backdropOptions = props.backdropOptions || { isOpen: false, child: <CircularProgress color="inherit" /> };

    useEffect(() => {
        const executeAction = async (): Promise<void> => {
            if (backdropOptions?.action) {
                await backdropOptions.action;
                setLoading(false);
            }
        };

        executeAction();
    });

    const isOpened = backdropOptions.isOpen && loading;
    return isOpened ? (
        <Backdrop classes={{ root: classes.root }} open={isOpened}>
            {backdropOptions.child}
        </Backdrop>
    ) : null;
};

export default CustomBackdrop;
