/* eslint-disable react/no-unescaped-entities */
import { InputLabel, Link } from '@material-ui/core';
import React, { FormEvent } from 'react';
import WithErrorHelper from '../../../hocs/WithErrorHelper/WithErrorHelper';
import WithFormContext from '../../../hocs/WithFormContext/WithFormContext';
import WithInsideLoading from '../../../hocs/WithInsideLoading/WithInsideLoading';
import Alert from '../../Common/Alert/Alert';
import Input from '../../Common/Input/Input';
import ArrowedButton from '../../Common/ArrowedButton/ArrowedButton';
import SimpleForm from '../../Common/SimpleForm/SimpleForm';
import PlainButton from '../../Common/PlainButton/PlainButton';

interface AccountRecoveryAttemptAdditionalInformationProps {
    onBack: (event: FormEvent<HTMLButtonElement>) => void;
    onSubmit: (event: FormEvent<HTMLButtonElement>) => void;
    errorMessage: string;
    success: boolean;
    loading: boolean;
    show: boolean;
}

const InputWithError = WithFormContext(WithErrorHelper(Input));
const ButtonWithLoading = WithInsideLoading(ArrowedButton);

const AccountRecoveryAttemptAdditionalInformation = (
    props: AccountRecoveryAttemptAdditionalInformationProps,
): JSX.Element => {
    return props.success ? (
        <SimpleForm spacing={1}>
            <InputLabel data-testid="recovery-request-sent-alert">
                Your account recovery request has been sent. We will review it and get in touch with you via phone or
                email.
            </InputLabel>
            <Link href="https://www.aia.org/">Go to AIA.org</Link>
        </SimpleForm>
    ) : (
        <SimpleForm spacing={1} style={props.show ? {} : { display: 'none' }}>
            {props.errorMessage ? (
                <Alert
                    data-testid="recovery-request-failed-alert"
                    style={{ textAlign: 'center' }}
                    hideIcon={true}
                    message={props.errorMessage}
                />
            ) : (
                <InputLabel style={{ marginBottom: '12px' }}>
                    Please enter the new email for your account and your phone number in case we need to get in touch
                    with you.
                </InputLabel>
            )}
            <InputWithError label="Email" id="email" name="email" required={true} />
            <InputWithError label="Confirm email" id="confirmEmail" name="confirmEmail" required={true} />
            <InputWithError label="Phone number" id="phoneNumber" name="phoneNumber" required={true} />

            <div style={{ marginTop: '10px' }}>
                <ButtonWithLoading
                    disabled={props.loading}
                    loading={props.loading}
                    onClick={props.onSubmit}
                    text="Confirm"
                />
                <PlainButton text="Go back" onClick={props.onBack} />
            </div>
        </SimpleForm>
    );
};

export default AccountRecoveryAttemptAdditionalInformation;
