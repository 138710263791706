import { API } from 'aws-amplify';
import { DuesEstimatorData } from '../components/DuesEstimator/DuesEstimator';
import { Order } from '../data_types/member-order';
import { MEMBERSHIP_TYPE_MAPPING, SELF_DESCRIBE_SUPERVISION } from './constants';

const getDues = async (props: DuesEstimatorData): Promise<Order> => {
    if (props.selfDescribe === SELF_DESCRIBE_SUPERVISION) {
        props.alternativeLicensePath = true;
    }
    let filter = '';
    Object.entries(props).forEach(([key, value]: [string, string]) => {
        if (key === 'selfDescribe') {
            value = MEMBERSHIP_TYPE_MAPPING[value] as string;
            key = 'membershipType';
        }
        filter = `${key}=${value}&${filter}`;
    });
    filter = filter.substring(0, filter.length - 1);

    const response = await API.get('membership', `/dues?${filter}`, {});
    return response.data[0];
};

export default getDues;
