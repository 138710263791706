import React, { cloneElement } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { OrderSuppDuesTableItemProps } from './Item/Item';
import { SuppDuesTableCellStyle } from './Table.style';

interface OrderSuppDuesTableComponentProps {
    items?: OrderSuppDuesTableItemProps[];
    children?: JSX.Element;
}

const showColumn = (value: 'stateDues' | 'localDues', items?: OrderSuppDuesTableItemProps[]): boolean => {
    return items && items.length > 0 ? !!items?.[0][value] : true;
};

const ShowStateAndLocalColumn = (props: {
    showColumn: boolean | number | undefined;
    showOtherColumn: boolean | number | undefined;
    children: string;
}): JSX.Element | null => {
    const classes = SuppDuesTableCellStyle();

    return props.showColumn ? (
        <TableCell classes={{ head: classes.head }} width={props.showOtherColumn ? '20%' : '10%'}>
            {props.children}
        </TableCell>
    ) : null;
};

const OrderSuppDuesTableComponent = (props: OrderSuppDuesTableComponentProps): JSX.Element => {
    const classes = SuppDuesTableCellStyle();

    const showStateColumn = showColumn('stateDues', props.items);
    const showLocalColumn = showColumn('localDues', props.items);

    return (
        <Table data-testid="supp-dues-table">
            <TableHead>
                <TableRow>
                    <TableCell classes={{ head: classes.head }} width="45%">
                        # Employees
                    </TableCell>
                    <ShowStateAndLocalColumn showColumn={showStateColumn} showOtherColumn={showLocalColumn}>
                        State
                    </ShowStateAndLocalColumn>
                    <ShowStateAndLocalColumn showColumn={showLocalColumn} showOtherColumn={showStateColumn}>
                        Local
                    </ShowStateAndLocalColumn>
                    <TableCell
                        classes={{ head: classes.head }}
                        width={showStateColumn && showLocalColumn ? '15%' : '10%'}
                        align="right"
                    >
                        Total
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.items?.map((elem) => {
                    return props.children ? cloneElement(props.children, { ...elem, key: elem.keyValue }) : null;
                })}
            </TableBody>
        </Table>
    );
};

export default OrderSuppDuesTableComponent;
