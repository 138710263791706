import { EmployeesInfo } from '../data_types/empoyees-info';
import { ModalAttributes } from '../data_types/modal';

export const CONTACT_STATUS = {
    TERMINATED: 'Terminated',
    NON_MEMBER: 'Non-Member',
    ACTIVE: 'Active',
    LAPSED: 'Lapsed',
};

export const DEFAULT_MODAL_CONTENT: ModalAttributes = {
    isOpen: false,
    title: 'Error',
    content: 'An error occurred.',
    onConfirm: (): void => window.location.reload(),
    confirmActionName: 'Reload',
};

export const INVALID_SESSION_MODAL_CONTENT: ModalAttributes = {
    ...DEFAULT_MODAL_CONTENT,
    title: 'Session expired',
    content: 'Your session has expired and you need to log in again.',
};

export const SESSION_INVALID_ERROR = 'not authenticated';

export const SUPPLEMENTAL_DUES_OPTIONS_NON_SOLE_PRACTITIONER =
    'I am not a sole practitioner, and I do not own or manage an architecture firm.';
export const SUPPLEMENTAL_DUES_OPTIONS_DESIGNATE_MEMBER =
    'I own or manage an architecture firm and designate another AIA member to pay the supplemental dues my firm owes.';
export const SUPPLEMENTAL_DUES_OPTIONS_SOLE_PRACTITIONER = 'I am a sole practitioner.';
export const SUPPLEMENTAL_DUES_OPTIONS_MANAGE_FIRM = 'I own or manage an architectural firm.';

export const EMPLOYEES_INFO: EmployeesInfo = {
    memberArchitectCount: {
        count: 'AIA_Member_Architect_Count__c',
        rate: 'AIA_Architect_Member_Rate__c',
        label: 'Architect Member',
    },
    nonMemberArchitectCount: {
        count: 'AIA_Non_member_Architect_Count__c',
        rate: 'AIA_Architect_Non_Member_Rate__c',
        label: 'Architect Non-Member',
    },
    associateCount: {
        count: 'AIA_Associate_Count__c',
        rate: 'AIA_Associate_Rate__c',
        label: 'Associate',
    },
    technicalStaffCount: {
        count: 'AIA_Technical_Staff_Count__c',
        rate: 'AIA_Tech_Staff_Rate__c',
        label: 'Technical Staff',
    },
    otherStaffCount: {
        count: 'AIA_Other_Staff_Count__c',
        rate: 'AIA_Other_Staff_Rate__c',
        label: 'Other Staff',
    },
};

export const ARCHIPAC_DONATION_NAME_EXPRESSION = 'ArchiPAC';
export const ARCHIPAC_DONATION_DISCLAIMER = `Contributions to ArchiPAC are not tax-deductible. Contributions are voluntary and will be used for political purposes; you may refuse to contribute without reprisal. Contribution guidelines are only suggestions; you may contribute more or less than the suggested amounts, and you will not be favored, or disadvantaged based on the amount of your contribution or your decision not to contribute. All contributions must be from U.S. citizens or lawful permanent residents (e.g., green card holders) from personal funds and may not be made by anyone who is a federal government contractor. Corporate contributions are prohibited by federal law. Federal law requires political committees to use their best efforts to collect and report the name, address, employer, and occupation of individuals whose contributions exceed $200 during a calendar year. Corporate contributions to ArchiPAC are deposited in a separate Architects Advocacy Fund to support other activities. If a contribution cannot be used for PAC purposes, members will be notified and the money will be transferred into the Architects Advocacy Fund (AAF), a non-PAC fund used for member advocacy. After receiving notice, members may decline the transfer and request a refund of the contribution.`;

export const ARCHIPAC_DONATION_NOTE = `ArchiPAC is prohibited from accepting business funds or contributions by company credit card or business checking account.`;

export const ORLANDO_FOUNDATION_FOR_ARCHITECTURE_DISCLAIMER =
    'Thank you for your contribution to The Orlando Foundation for Architecture (OFA), a non-profit organization dedicated to public education that inspires people to discover WHY DESIGN MATTERS. No goods or services were provided in exchange for this contribution. OFA is an exempt organization as described in Section 501(c)(3) of the Internal Revenue Code. OFA offers 100% deductibility, as no political activities are permitted.';
export const SAN_FRANCISCO_SPECIAL_ASSESSMENT_DISCLAIMER =
    'Thank you for your special assessment of $170 to AIA San Francisco, towards the Center for Architecture + Design. The assessment will be recognized as a donation and may be deductible as a trade or business expense. Architect Members will receive a Center membership, which complements your AIASF membership and includes complimentary access to public programs and member discounts. Learn more at https://centersf.org/shape-your-city.';
export const TAMPA_BAY_CENTER_FOR_ARCHITECTURE_DISCLAIMER =
    'Thank you for your contribution to Center for Architecture & Design, Tampa Bay (CA+D) a nonprofit organization dedicated to promoting the power of design to shape our community.  No goods or services were provided in exchange for this contribution. CA+D is an exempt organization as described in Section 501(c)(3) of the Internal Revenue Code. CA+D offers 100% deductibility, as no political activities are permitted. #For questions about your AIA membership, please contact AIA Headquarters at (800) 242 3837, option 2, membersupport@aia.org.';

export const PARTIAL_AIAU_SUBSCRIPTION_OPTIONAL_ITEM_NAME = 'AIAU Subscription';
export const INSTALLMENT_ADMINISTRATION_FEE_NAME = 'Installment';

export const MEMBERSHIP_TYPE_ARCHITECT = process.env.REACT_APP_MEMBERSHIP_TYPE_ARCHITECT || 'Architect';
export const MEMBERSHIP_TYPE_INTL_ASSOCIATE = process.env.REACT_APP_MEMBERSHIP_TYPE_INTL_ASSOCIATE || 'Intl Associate';
export const MEMBERSHIP_TYPE_ASSOCIATE = process.env.REACT_APP_MEMBERSHIP_TYPE_ASSOCIATE || 'Associate';
export const MEMBERSHIP_TYPE_HONORARY = process.env.REACT_APP_MEMBERSHIP_TYPE_HONORARY || 'Honorary';
export const MEMBERSHIP_TYPE_ALLIED = process.env.REACT_APP_MEMBERSHIP_TYPE_ALLIED || 'Allied';

export const SELF_DESCRIBE_US_ARCHITECT = 'us-license-architect';
export const SELF_DESCRIBE_NON_US_ARCHITECT = 'non-us-license-int-associate';
export const SELF_DESCRIBE_NEW_GRADUATE = 'new-graduate-associate';
export const SELF_DESCRIBE_AXP_ENROLLED = 'axp-enrolled-associate';
export const SELF_DESCRIBE_ARCHITECT_DEGREE = 'architect-degree-associate';
export const SELF_DESCRIBE_SUPERVISION = 'licensed-supervision-associate';
export const SELF_DESCRIBE_FACULTY_MEMBER = 'faculty-member-associate';
export const SELF_DESCRIBE_ALLIED = 'professional-allied';

export const JOIN_SELF_DESCRIBE_OPTIONS: { label: string; value: string }[] = [
    {
        label: 'I hold an active architecture license from a U.S. licensing authority.',
        value: SELF_DESCRIBE_US_ARCHITECT,
    },
    {
        label: 'I hold an active architecture license from a non-U.S. licensing authority.',
        value: SELF_DESCRIBE_NON_US_ARCHITECT,
    },
    {
        label: 'I am a new graduate.',
        value: SELF_DESCRIBE_NEW_GRADUATE,
    },
    {
        label: 'I am enrolled in AXP.',
        value: SELF_DESCRIBE_AXP_ENROLLED,
    },
    {
        label: 'I am not licensed, but I have an architecture degree.',
        value: SELF_DESCRIBE_ARCHITECT_DEGREE,
    },
    {
        label: 'I do not have an architecture degree, but I am working under the supervision of a licensed architect.',
        value: SELF_DESCRIBE_SUPERVISION,
    },
    {
        label: 'I am a faculty member in an architecture program.',
        value: SELF_DESCRIBE_FACULTY_MEMBER,
    },
    {
        label: 'I am an allied professional.',
        value: SELF_DESCRIBE_ALLIED,
    },
];

export const MEMBERSHIP_TYPE_MAPPING: { [key: string]: string | undefined } = {
    [SELF_DESCRIBE_US_ARCHITECT]: MEMBERSHIP_TYPE_ARCHITECT,
    [SELF_DESCRIBE_NON_US_ARCHITECT]: MEMBERSHIP_TYPE_INTL_ASSOCIATE,
    [SELF_DESCRIBE_NEW_GRADUATE]: MEMBERSHIP_TYPE_ASSOCIATE,
    [SELF_DESCRIBE_AXP_ENROLLED]: MEMBERSHIP_TYPE_ASSOCIATE,
    [SELF_DESCRIBE_ARCHITECT_DEGREE]: MEMBERSHIP_TYPE_ASSOCIATE,
    [SELF_DESCRIBE_SUPERVISION]: MEMBERSHIP_TYPE_ASSOCIATE,
    [SELF_DESCRIBE_FACULTY_MEMBER]: MEMBERSHIP_TYPE_ASSOCIATE,
    [SELF_DESCRIBE_ALLIED]: MEMBERSHIP_TYPE_ALLIED,
};

export const MEMBERSHIP_COUNTRY_UNITED_STATES = 'United States';

export const DUES_ESTIMATOR_DISCLAIMER = `Disclaimer: The dues calculator is a tool to estimate new or reinstating prospective membership
dues for national, state and local chapter assignment before the join process. In some cases,
the dues amounts may be different based on the estimated amounts provided before joining.
Changes in total cost may depend on membership categories, prior membership with the AIA or
local or state chapter assignment. If you need the actual membership dues amount, please start
the online join process or contact AIA member services at (800) 242-3837, option 2 or 1+ (202)
626-7300, option 2 (outside the U.S.).`;

export const LOCAL_STORAGE_DUES_ESTIMATOR_VALUES_KEY = 'dues-estimator-default-values';
export const CES_AUDIT_FAILED = 'Audited-Failed';
export const UNDER_CES_AUDIT = 'Under Audit';
