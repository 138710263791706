/* eslint-disable @typescript-eslint/no-explicit-any */
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import {
    SUPPLEMENTAL_DUES_OPTIONS_DESIGNATE_MEMBER,
    SUPPLEMENTAL_DUES_OPTIONS_MANAGE_FIRM,
    SUPPLEMENTAL_DUES_OPTIONS_SOLE_PRACTITIONER,
} from './constants';
import { Resolver } from 'react-hook-form';

const requiredMessage = 'This information is required.';
const employeeCountCondition = Joi.alternatives().conditional('supplementalDuesMemberType', {
    is: Joi.string().valid(SUPPLEMENTAL_DUES_OPTIONS_SOLE_PRACTITIONER, SUPPLEMENTAL_DUES_OPTIONS_MANAGE_FIRM),
    then: Joi.number().required(),
});

const employeeArchitectCountCondition = Joi.alternatives().conditional('supplementalDuesMemberType', {
    is: Joi.string().valid(SUPPLEMENTAL_DUES_OPTIONS_SOLE_PRACTITIONER, SUPPLEMENTAL_DUES_OPTIONS_MANAGE_FIRM),
    then: Joi.number().min(1).required().messages({ 'number.min': 'You should include at least yourself.' }),
});

const joiSchemaMessages = {
    'number.base': requiredMessage,
    'string.empty': requiredMessage,
    'any.invalid': requiredMessage,
};

export const generateRenewSchema = (chargeSuppDues: boolean): Resolver<Record<string, any>, object> => {
    return joiResolver(
        Joi.object({
            subscriptionPlanId: Joi.string(),
            supplementalDuesMemberType: chargeSuppDues ? Joi.string().required() : Joi.valid(),
            memberName: Joi.alternatives().conditional('supplementalDuesMemberType', {
                is: SUPPLEMENTAL_DUES_OPTIONS_DESIGNATE_MEMBER,
                then: Joi.string().required(),
            }),
            memberId: Joi.alternatives().conditional('supplementalDuesMemberType', {
                is: SUPPLEMENTAL_DUES_OPTIONS_DESIGNATE_MEMBER,
                then: Joi.string().allow(''),
            }),
            memberArchitectCount: employeeArchitectCountCondition,
            nonMemberArchitectCount: employeeCountCondition,
            associateCount: employeeCountCondition,
            technicalStaffCount: employeeCountCondition,
            otherStaffCount: employeeCountCondition,
            additionalPackages: Joi.array().items(Joi.object()),
            confirmLicense: Joi.boolean().invalid(false),
            confirmTerms: Joi.boolean().invalid(false),
            confirmAlliedProfessional: Joi.boolean().invalid(false),
            confirmInstallments: Joi.boolean().invalid(false),
            autoRenew: Joi.boolean(),
        }).prefs({
            messages: joiSchemaMessages,
        }),
    );
};
