import React, { memo } from 'react';
import { UseFormMethods } from 'react-hook-form';
import Alert from '../../Common/Alert/Alert';

interface OrderAlertProps {
    formMethods?: UseFormMethods;
}

interface OrderAlertMemoizedProps {
    hasErrors: boolean;
}

const OrderAlertMemoized = memo(
    (props: OrderAlertMemoizedProps) => {
        return props.hasErrors ? <Alert message="Some fields require your attention." /> : null;
    },
    (prev: OrderAlertMemoizedProps, next: OrderAlertMemoizedProps) => {
        return prev.hasErrors === next.hasErrors;
    },
);
OrderAlertMemoized.displayName = 'OrderAlertMemoized';

const OrderAlert = (props: OrderAlertProps): JSX.Element | null => {
    const hasErrors: boolean = props.formMethods?.errors ? Object.keys(props.formMethods?.errors).length > 0 : false;

    return <OrderAlertMemoized hasErrors={hasErrors} />;
};

export default OrderAlert;
