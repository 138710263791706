import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { generateAccountRecoverySchema } from '../../utils/generate-account-recovery-schema';
import AccountRecoveryComponent from './AccountRecoveryComponent';
import { useHistory } from 'react-router-dom';

const AccountRecovery = (): JSX.Element => {
    const formMethods = useForm({ resolver: generateAccountRecoverySchema(), defaultValues: { action: '' } });
    const history = useHistory();
    const [errors, setErrors] = useState(false);

    const onSubmitHandler = (data: { action: string }): void => {
        if (data.action === 'forgotPassword') {
            window.location.assign(`https://${process.env.REACT_APP_AUTH_DOMAIN}/forgot-password`);
        } else {
            history.push('/account-recovery-attempt');
        }
    };

    const onErrorHandler = (): void => {
        setErrors(true);
    };

    return (
        <FormProvider {...formMethods}>
            <AccountRecoveryComponent
                errors={errors}
                onSubmit={formMethods.handleSubmit(onSubmitHandler, onErrorHandler)}
            />
        </FormProvider>
    );
};

export default AccountRecovery;
