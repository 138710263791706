import { Chapters, EmployeesTotal } from '../data_types/member-order';
import { EMPLOYEES_INFO } from './constants';

interface SupplementalDues {
    name: string;
    label: string;
    stateDues: number;
    localDues: number;
}

const getSupplementalDues = (employeeTypes: string[], chapters: Chapters): SupplementalDues[] => {
    return employeeTypes.map((employeeType) => ({
        name: employeeType,
        label: EMPLOYEES_INFO[employeeType].label,
        stateDues: (chapters.State && (chapters.State[EMPLOYEES_INFO[employeeType].rate] as number)) || 0,
        localDues: (chapters.Local && (chapters.Local[EMPLOYEES_INFO[employeeType].rate] as number)) || 0,
    }));
};

export interface SuppDuesTotal {
    state: number;
    local: number;
}

const calculateSupplementalDuesTotal = (employeesTotal: EmployeesTotal, chapters: Chapters): SuppDuesTotal => {
    const supplementalDues = getSupplementalDues(Object.keys(employeesTotal), chapters);
    let stateTotal = supplementalDues.reduce((acc, elem) => acc + elem.stateDues * (employeesTotal[elem.name] || 0), 0);
    const stateMinRate = chapters.State?.AIA_Min_Limit__c || 0;
    const stateMaxRate = chapters.State?.AIA_Max_Limit__c || stateTotal;

    if (stateTotal < stateMinRate) {
        stateTotal = stateMinRate;
    } else if (stateMaxRate !== 0 && stateTotal > stateMaxRate) {
        stateTotal = stateMaxRate;
    }

    let localTotal = supplementalDues.reduce((acc, elem) => acc + elem.localDues * (employeesTotal[elem.name] || 0), 0);
    const localMinRate = chapters.Local?.AIA_Min_Limit__c || 0;
    const localMaxRate = chapters.Local?.AIA_Max_Limit__c || localTotal;

    if (localTotal < localMinRate) {
        localTotal = localMinRate;
    } else if (localMaxRate !== 0 && localTotal > localMaxRate) {
        localTotal = localMaxRate;
    }

    return {
        state: stateTotal,
        local: localTotal,
    };
};

export default calculateSupplementalDuesTotal;
