import { makeStyles } from '@material-ui/core';

export const AppContainerStyle = makeStyles(({ breakpoints }) => ({
    root: {
        padding: '24px 65px',
        minHeight: 'calc(100vh - 146px)',
        [breakpoints.down('xs')]: {
            minHeight: 'calc(100vh - 228px)',
            padding: '12px 24px',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
}));
