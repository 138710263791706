/* eslint-disable @typescript-eslint/no-explicit-any */
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { Resolver } from 'react-hook-form';

const requiredMessage = 'This information is required.';
const validDateMessage = 'Date is not valid.';
const joiSchemaMessages = {
    'string.empty': requiredMessage,
    'date.base': validDateMessage,
};

const stepCondition = (step: number, stepLimit: number, condition: Joi.AnySchema): Joi.AnySchema => {
    return step >= stepLimit ? condition : Joi.valid();
};

const emailCondition = (step: number): Joi.AnySchema => {
    return stepCondition(
        step,
        3,
        Joi.string()
            .email({ tlds: { allow: false } })
            .required(),
    );
};

const confirmEmailCondition = (step: number): Joi.AnySchema => {
    return stepCondition(
        step,
        3,
        Joi.any()
            .equal(Joi.ref('email'))
            .required()
            .label('Confirm email')
            .options({ messages: { 'any.only': '{{#label}} does not match' } }),
    );
};

export const generateRecoveryAttemptFormSchema = (step: 1 | 2 | 3): Resolver<Record<string, any>, object> => {
    return joiResolver(
        Joi.object({
            firstName: Joi.string().required(),
            lastName: Joi.string().required(),
            previousEmail: Joi.string()
                .email({ tlds: { allow: false } })
                .allow(''),
            memberNumber: Joi.string().empty('').default(null),
            birthdate: stepCondition(step, 2, Joi.date().required()),
            degreeGraduationYear: stepCondition(step, 2, Joi.number().empty('').default(null)),
            universityAttended: stepCondition(step, 2, Joi.string().allow('')),
            stateFirstLicense: stepCondition(step, 2, Joi.string().allow('')),
            previousChapter: stepCondition(step, 2, Joi.string().allow('')),
            email: emailCondition(step),
            confirmEmail: confirmEmailCondition(step),
            phoneNumber: stepCondition(step, 3, Joi.string().required()),
        }).prefs({
            messages: joiSchemaMessages,
        }),
    );
};
