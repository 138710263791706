import React from 'react';
import { Typography } from '@material-ui/core';
import WithFormContext from '../../../../hocs/WithFormContext/WithFormContext';
import WithErrorHelper from '../../../../hocs/WithErrorHelper/WithErrorHelper';
import Checkbox from '../../../Common/Checkbox/Checkbox';
import { MEMBERSHIP_TYPE_ARCHITECT } from '../../../../utils/constants';

interface OrderConfirmLicenseCheckboxProps {
    membershipType?: string;
}

const CheckboxWithError = WithFormContext(WithErrorHelper(Checkbox));

const OrderConfirmLicenseCheckbox = (props: OrderConfirmLicenseCheckboxProps): JSX.Element | null => {
    const show = props.membershipType === MEMBERSHIP_TYPE_ARCHITECT;

    const label = (
        <>
            I attest that I am currently licensed to practice architecture in the U.S.{' '}
            <Typography color="error" component="span">
                *
            </Typography>
        </>
    );

    return show ? (
        <CheckboxWithError data-testid="license-checkbox" id="confirmLicense" name="confirmLicense" label={label} />
    ) : null;
};

export default OrderConfirmLicenseCheckbox;
