import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { FormControlLabel, Radio as MuiRadio } from '@material-ui/core';
import { RadioProps as MuiRadioProps } from '@material-ui/core/Radio/Radio';
import { RadioStyle, FormControlLabelStyle } from './Radio.style';

interface RadioProps extends MuiRadioProps {
    formMethods?: UseFormMethods;
    label: JSX.Element | string;
}

const Radio = (props: RadioProps): JSX.Element => {
    const radioClasses = RadioStyle();
    const formControlLabelClasses = FormControlLabelStyle();
    const { formMethods, label, style, ...inputProps } = props;

    return (
        <FormControlLabel
            style={style}
            classes={{ label: formControlLabelClasses.label }}
            control={
                <MuiRadio
                    classes={{ root: radioClasses.root, checked: radioClasses.checked }}
                    inputRef={formMethods?.register}
                    {...inputProps}
                />
            }
            label={label}
        />
    );
};

export default Radio;
