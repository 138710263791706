import { makeStyles } from '@material-ui/core';

export const CheckboxStyle = makeStyles((theme) => ({
    root: {
        alignSelf: 'flex-start',
        '&$checked': {
            color: theme.palette.error.main,
        },
    },
    checked: {},
}));
