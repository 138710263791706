/* eslint-disable react/no-unescaped-entities */
import React, { FormEvent } from 'react';
import WithErrorHelper from '../../../hocs/WithErrorHelper/WithErrorHelper';
import WithFormContext from '../../../hocs/WithFormContext/WithFormContext';
import Input from '../../Common/Input/Input';
import ArrowedButton from '../../Common/ArrowedButton/ArrowedButton';
import SimpleForm from '../../Common/SimpleForm/SimpleForm';
import { InputLabel } from '@material-ui/core';

interface AccountRecoveryAttemptBasicInformationProps {
    onSubmit: (event: FormEvent<HTMLButtonElement>) => void;
    show: boolean;
}

const InputWithError = WithFormContext(WithErrorHelper(Input));

const AccountRecoveryAttemptBasicInformation = (props: AccountRecoveryAttemptBasicInformationProps): JSX.Element => {
    return (
        <SimpleForm spacing={1} style={props.show ? {} : { display: 'none' }}>
            <InputLabel style={{ marginBottom: '12px' }}>
                Please enter the following information so we can identify your account.
            </InputLabel>
            <InputWithError label="First name" id="firstName" name="firstName" required={true} />
            <InputWithError label="Last name" id="lastName" name="lastName" required={true} />
            <InputWithError label="Previous email" id="previousEmail" name="previousEmail" />
            <InputWithError label="Member ID" id="memberNumber" name="memberNumber" />
            <div style={{ marginTop: '10px' }}>
                <ArrowedButton data-testid="step1-next" onClick={props.onSubmit} text="Next" />
            </div>
        </SimpleForm>
    );
};

export default AccountRecoveryAttemptBasicInformation;
