import { makeStyles } from '@material-ui/core';

export const OptionalProductsItemCellStyle = makeStyles({
    root: {
        padding: '12px 16px',
        '&:last-of-type': {
            textAlign: 'right',
            minWidth: '110px',
        },
        verticalAlign: 'top',
    },
});
