/* eslint-disable react/no-unescaped-entities */
import { Typography } from '@material-ui/core';
import React from 'react';
import WithErrorHelper from '../../hocs/WithErrorHelper/WithErrorHelper';
import WithFormContext from '../../hocs/WithFormContext/WithFormContext';
import WithInsideLoading from '../../hocs/WithInsideLoading/WithInsideLoading';
import { ContactStatus } from '../../utils/get-contact-statuses';
import ArrowedButton from '../Common/ArrowedButton/ArrowedButton';
import Input from '../Common/Input/Input';
import { LandingStyle } from './LandingHome.style';
import LandingAccountFound from './LandingAccountFound';
import LandingAccountNotFound from './LandingAccountNotFound';
import BoxWithTitle from '../Common/BoxWithTitle/BoxWithTitle';

const InputWithError = WithFormContext(WithErrorHelper(Input));
const ButtonWithLoading = WithInsideLoading(ArrowedButton);

export interface LandingHomeComponentProps {
    onSubmit: (e?: React.BaseSyntheticEvent<object, unknown, unknown> | undefined) => Promise<void>;
    onBack: () => void;
    status: null | 'Found' | 'Not-Found';
    email: string;
    contactStatus: null | ContactStatus;
    loading: boolean;
}

const LandingHomeComponent = (props: LandingHomeComponentProps): JSX.Element => {
    const classes = LandingStyle();

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        props.onSubmit(event);
    };

    return props.status === null ? (
        <form noValidate className={classes.form} onSubmit={handleFormSubmit}>
            <BoxWithTitle title="Let's get started" className={classes.box}>
                <Typography variant="h3">Please enter your email to renew or create your AIA membership.</Typography>
                <InputWithError label="Email" id="email " name="email" color="primary" required={true} />
                <ButtonWithLoading
                    style={{ height: '40px' }}
                    disabled={props.loading}
                    loading={props.loading}
                    onClick={props.onSubmit}
                    text="Continue"
                />
            </BoxWithTitle>
        </form>
    ) : props.status === 'Not-Found' ? (
        <LandingAccountNotFound email={props.email} onBack={props.onBack} />
    ) : (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        <LandingAccountFound contactStatus={props.contactStatus!} onBack={props.onBack} />
    );
};

export default LandingHomeComponent;
