import { makeStyles } from '@material-ui/core';

export const SuppDuesTableCellStyle = makeStyles({
    head: {
        padding: '16px 5px 5px 5px',
        border: 'none',
        '&:first-of-type': {
            paddingLeft: '12px',
        },
        '&:last-of-type': {
            paddingRight: '0px',
            textAlign: 'right',
        },
        '&:not(:first-child)': {
            paddingRight: '0px',
            textAlign: 'right',
        },
    },
});
