import Link from '@material-ui/core/Link/Link';
import Modal from 'components/Common/Modal/Modal';
import React from 'react';

interface ArchiPACModalProps {
    showAutoRenewModal: boolean;
    setShowAutoRenewModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ArchiPACModal = ({ showAutoRenewModal, setShowAutoRenewModal }: ArchiPACModalProps): JSX.Element => {
    const modalOptions = {
        isOpen: showAutoRenewModal,
        title: 'ArchiPAC Donation Note',
        content: (
            <>
                ArchiPAC contributions are for one year only and will not be enrolled in the AIA Automatic Membership
                Renewal Program. You can contribute to ArchiPAC anytime during the year by visiting the{' '}
                <Link href="https://www.aia.org/pages/3541-archipac">Advocacy page</Link> and logging in to your{' '}
                <Link href="https://www.aia.org">AIA</Link> account.
            </>
        ),
        onCancel: (): void => {
            setShowAutoRenewModal(false);
        },
        cancelActionName: 'Close',
    };
    return <Modal modalOptions={modalOptions}></Modal>;
};

export default ArchiPACModal;
