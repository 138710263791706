/* eslint-disable react/no-unescaped-entities */
import { Grid } from '@material-ui/core';
import React from 'react';
import LandingHome from '../components/LandingHome/LandingHome';
import { HomeStyle } from './Home.style';

const ScreensHome = (): JSX.Element | null => {
    const classes = HomeStyle();

    return (
        <Grid className={classes.root} justify="center" alignItems="center" container>
            <LandingHome />
        </Grid>
    );
};

export default ScreensHome;
