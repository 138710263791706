import React from 'react';
import { Link, List, ListItem, Typography } from '@material-ui/core';

const ItemWithLink = (props: { href: string; children: string }): JSX.Element => {
    return (
        <ListItem disableGutters={true}>
            <Typography variant="body1">
                <Link href={props.href} target="_blank">
                    {props.children} {'>'}
                </Link>
            </Typography>
        </ListItem>
    );
};

const Disclosures = (): JSX.Element => {
    return (
        <List>
            <ItemWithLink href="https://www.aia.org/privacy-policy">Privacy</ItemWithLink>
            <ItemWithLink href="https://www.aia.org/about-aia/professional-standards">Ethics statement</ItemWithLink>
            <ItemWithLink href="https://www.aia.org/terms-use">Terms & conditions</ItemWithLink>
        </List>
    );
};

export default Disclosures;
