import React from 'react';
import { UseFormMethods, useWatch } from 'react-hook-form';
import WithInsideLoading, { WithLoadingProps } from '../../../../hocs/WithInsideLoading/WithInsideLoading';
import ArrowedButton, { ArrowedButtonProps } from '../../../Common/ArrowedButton/ArrowedButton';

interface OrderPayOfflineButtonProps extends WithLoadingProps, ArrowedButtonProps {
    formMethods?: UseFormMethods;
    defaultSubscriptionPlanId: string | undefined;
}

const ArrowedButtonWithLoading = WithInsideLoading(ArrowedButton);

const OrderPayOfflineButton = ({
    defaultSubscriptionPlanId,
    disabled,
    formMethods,
    ...orderButtonProps
}: OrderPayOfflineButtonProps): JSX.Element => {
    const subscriptionPlanId = useWatch({
        control: formMethods?.control,
        name: 'subscriptionPlanId',
        defaultValue: defaultSubscriptionPlanId,
    });

    disabled = disabled || subscriptionPlanId !== defaultSubscriptionPlanId;

    return <ArrowedButtonWithLoading disabled={disabled} {...orderButtonProps} />;
};

export default OrderPayOfflineButton;
