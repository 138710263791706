import { FunctionComponent } from 'react';
import { UseFormMethods, useWatch } from 'react-hook-form';
import WithFormContext from '../../../hocs/WithFormContext/WithFormContext';

interface ConditionalFormPropertyRendererProps {
    formPropertyName: string;
    showValues: unknown[];
    formMethods?: UseFormMethods;
}

const ConditionalFormPropertyRenderer: FunctionComponent<ConditionalFormPropertyRendererProps> = (
    props,
): JSX.Element | null => {
    const property = useWatch({
        control: props.formMethods?.control,
        name: props.formPropertyName,
        defaultValue: props.formMethods?.getValues(props.formPropertyName),
    });

    const show = props.showValues.includes(property);

    return show ? (props.children as JSX.Element) : null;
};

export default WithFormContext(ConditionalFormPropertyRenderer);
