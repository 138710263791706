import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { formatNumber } from '../../../../../utils/format-number';
import { OrderAdditionalFeesTableItemCellStyle } from './Item.style';

interface OrderAdditionalFeesTableItemProps {
    id?: string;
    name?: string;
    price?: number;
}

const OrderAdditionalFeesTableItem = (props: OrderAdditionalFeesTableItemProps): JSX.Element => {
    const classes = OrderAdditionalFeesTableItemCellStyle();
    return (
        <TableRow key={props.id}>
            <TableCell classes={{ root: classes.root }}>{props.name}</TableCell>
            <TableCell classes={{ root: classes.root }}>{formatNumber(props.price)}</TableCell>
        </TableRow>
    );
};

export default OrderAdditionalFeesTableItem;
