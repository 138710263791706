import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { Chapters } from '../../../data_types/member-order';

interface MembershipTaxesProps {
    chapters: Chapters;
}

const MembershipTaxes = (props: MembershipTaxesProps): JSX.Element => {
    const renderChapterTax = (chapterName: string, deductibility: number): JSX.Element => (
        <ListItem disableGutters={true}>
            <ListItemText primary={chapterName} secondary={`${deductibility}%`}></ListItemText>
        </ListItem>
    );

    return (
        <List>
            {props.chapters.National?.Deductibility__c
                ? renderChapterTax('National', props.chapters.National.Deductibility__c)
                : null}
            {props.chapters.State?.Deductibility__c
                ? renderChapterTax('State', props.chapters.State.Deductibility__c)
                : null}
            {props.chapters.Local?.Deductibility__c
                ? renderChapterTax('Local', props.chapters.Local.Deductibility__c)
                : null}
        </List>
    );
};

export default MembershipTaxes;
