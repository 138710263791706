import React from 'react';
import { Divider as MuiDivider, Grid, Typography, withStyles } from '@material-ui/core';

const Divider = withStyles({
    root: {
        width: '100%',
    },
})(MuiDivider);

interface ScreensErrorProps {
    errorHeader: string;
    errorMessage: string;
}

const ScreensError = (props: ScreensErrorProps): JSX.Element => {
    return (
        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item>
                <Typography variant="h1" color="error">
                    {props.errorHeader}
                </Typography>
            </Grid>
            <Divider />
            <Grid item>
                <Typography variant="subtitle2" gutterBottom={true}>
                    {props.errorMessage}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ScreensError;
