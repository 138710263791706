import React from 'react';
import { CircularProgress as MuiCircularProgress, withStyles } from '@material-ui/core';
import ScreensError from '../../screens/Error';

interface WithLoadingProps {
    loading?: boolean;
    error?: boolean;
}

const CircularProgress = withStyles(() => ({
    root: {},
}))(MuiCircularProgress);

// eslint-disable-next-line react/display-name
const WithLoading = <P extends object>(Component: React.ComponentType<P>): React.FC<P & WithLoadingProps> => ({
    loading,
    error,
    ...props
}: WithLoadingProps): JSX.Element => {
    return loading ? (
        <CircularProgress />
    ) : error ? (
        <ScreensError {...{ errorHeader: '500 Error', errorMessage: 'Internal server error' }} />
    ) : (
        <Component {...(props as P)} />
    );
};

export default WithLoading;
