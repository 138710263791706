import React from 'react';
import { Typography } from '@material-ui/core';

const ScreensUnderAudit = (): JSX.Element => {
    return (
        <Typography variant="h2">
            <p>
                You are currently under AIA CES audit for failure to meet your annual continuing education requirement.
                Your membership is not currently eligible for renewal. Please contact&nbsp;
                <a key="ces-email" href="mailto:ces@aia.org">
                    ces@aia.org
                </a>
                &nbsp;for more details.
            </p>
        </Typography>
    );
};

export default ScreensUnderAudit;
