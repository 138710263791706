import React from 'react';
import { Link, Typography } from '@material-ui/core';
import WithFormContext from '../../../../hocs/WithFormContext/WithFormContext';
import WithErrorHelper from '../../../../hocs/WithErrorHelper/WithErrorHelper';
import Checkbox from '../../../Common/Checkbox/Checkbox';

const CheckboxWithError = WithFormContext(WithErrorHelper(Checkbox));

const OrderConfirmTermsCheckbox = (): JSX.Element | null => {
    const label = (
        <>
            I agree to the{' '}
            <Link href="https://www.aia.org/terms-conditions-aia-membership" target="_blank" rel="noopener noreferrer">
                AIA Membership Terms and Conditions
            </Link>{' '}
            <Typography color="error" component="span">
                *
            </Typography>
        </>
    );

    return <CheckboxWithError data-testid="terms-checkbox" id="confirmTerms" name="confirmTerms" label={label} />;
};

export default OrderConfirmTermsCheckbox;
