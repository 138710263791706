import { makeStyles } from '@material-ui/core';

export const OneAtLeftStyle = makeStyles(({ breakpoints }) => ({
    root: {
        flexDirection: 'row',
        [breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'inherit',
        },
        '&> .MuiGrid-item:first-child': {
            flexGrow: 1,
        },
    },
}));
