import React from 'react';
import AccountRecoveryAttemptContainer from '../components/AccountRecovery/Attempt/Attempt';
import BoxWithTitle from '../components/Common/BoxWithTitle/BoxWithTitle';

const AccountRecoveryAttempt = (): JSX.Element => {
    return (
        <BoxWithTitle width="450px" title="Recover your account">
            <AccountRecoveryAttemptContainer />
        </BoxWithTitle>
    );
};

export default AccountRecoveryAttempt;
