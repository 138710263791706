import { red } from '@material-ui/core/colors';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// A custom theme for this app
const palette = {
    primary: {
        main: '#ef4135',
        dark: 'black',
    },
    secondary: {
        main: '#efefef',
        light: '#e1e1e1',
        dark: '#808080',
    },
    error: {
        main: red.A400,
    },
    background: {
        default: '#fff',
    },
};

const typography = {
    fontFamily: 'architype-light,sans-serif',
    h1: {
        fontSize: '6.4rem',
        color: palette.primary.main,
    },
    h2: {
        fontSize: '2em',
    },
    h3: {
        fontSize: '1.6em',
    },
    body1: {
        fontSize: '1.6rem',
    },
    body2: {
        fontSize: '1.6rem',
    },
    subtitle2: {
        fontSize: '1.6rem',
        fontWeight: 500,
    },
    caption: {
        fontSize: '1.4rem',
    },
};

let theme = createMuiTheme({
    typography,
    palette,
    overrides: {
        MuiCssBaseline: {
            '@global': {
                html: {
                    'font-size': '10px',
                },
                body: {
                    'font-size': '1.6rem',
                    height: '100%',
                },
            },
        },
        MuiListItemText: {
            multiline: {
                marginTop: 0,
                marginBottom: 0,
            },
        },
        MuiTableCell: {
            root: {
                borderBottom: 'none',
            },
            head: {
                borderBottom: `1px solid ${palette.secondary.light}`,
            },
        },
        MuiFormLabel: {
            asterisk: {
                color: red.A400,
                '&$error': {
                    color: red.A400,
                },
            },
        },
        MuiFilledInput: {
            underline: {
                '&:before': {
                    borderBottom: 'none',
                },
            },
            input: {
                backgroundColor: '#ffff',
                borderRadius: '0px',
            },
        },
    },
});

theme = responsiveFontSizes(theme);

export default theme;
