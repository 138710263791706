import { makeStyles } from '@material-ui/core';

export const GridStyle = makeStyles({
    root: {
        '& > div': {
            width: '100%',
            textAlign: 'center',
            '& .MuiFormControl-root, button': {
                width: '100%',
            },
            '& button': {
                marginBottom: '10px',
            },
        },
    },
});
