import React, { useState, useEffect } from 'react';
import { RouteProps } from 'react-router';
import { Auth } from 'aws-amplify';
import { Redirect } from 'react-router-dom';

const SSORoute: React.FC<RouteProps> = (): JSX.Element | null => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(() => setIsAuthenticated(true))
            .catch(() => Auth.federatedSignIn());
    }, []);

    return isAuthenticated ? <Redirect to="/" /> : null;
};

export default SSORoute;
