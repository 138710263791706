import React, { cloneElement, memo } from 'react';
import { FieldErrors, UseFormMethods } from 'react-hook-form';
import { FormControl, FormHelperText } from '@material-ui/core';

type WithErrorProps = {
    name?: string;
    required?: boolean;
    formMethods?: UseFormMethods;
};

type FormControlMemoProps = {
    error: FieldErrors;
    required?: boolean;
    children: JSX.Element;
};

const FormControlMemo = memo(
    (props: FormControlMemoProps) => {
        const hasError = !!props.error;

        return (
            <FormControl error={hasError} component="fieldset">
                {cloneElement(props.children, { 'aria-invalid': hasError })}
                {hasError && <FormHelperText role="alert">{props.error.message}</FormHelperText>}
            </FormControl>
        );
    },
    (prev: FormControlMemoProps, next: FormControlMemoProps) => {
        return prev.error === next.error && prev.required === next.required;
    },
);
FormControlMemo.displayName = 'FormControlMemo';

// eslint-disable-next-line react/display-name
const WithErrorHelper = <P extends object>(Component: React.ComponentType<P>): React.FC<P & WithErrorProps> => ({
    name,
    formMethods,
    required,
    ...props
}: P & WithErrorProps): JSX.Element => {
    const error = name ? formMethods?.errors?.[name] : null;

    return (
        <FormControlMemo error={error} required={required}>
            <Component {...(props as P)} name={name} formMethods={formMethods} required={required} />
        </FormControlMemo>
    );
};

export default WithErrorHelper;
