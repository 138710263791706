import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { Contact } from '../../../data_types/member-order';

interface MembershipMemberInfoProps {
    contact?: Contact;
}

const MembershipMemberInfo = (props: MembershipMemberInfoProps): JSX.Element => {
    return (
        <List>
            <ListItem disableGutters={true}>
                <ListItemText primary="Member ID" secondary={props.contact?.AIANumber}></ListItemText>
            </ListItem>
            <ListItem disableGutters={true}>
                <ListItemText primary="Membership year" secondary={props.contact?.renewYear}></ListItemText>
            </ListItem>
            <ListItem disableGutters={true}>
                <ListItemText primary="Membership type" secondary={props.contact?.membershipType}></ListItemText>
            </ListItem>
            <ListItem disableGutters={true}>
                <ListItemText
                    primary="Payment due date"
                    secondary={`01/15/${props.contact?.renewYear?.toString().substring(2)}`}
                ></ListItemText>
            </ListItem>
        </List>
    );
};

export default MembershipMemberInfo;
