import { API, Auth } from 'aws-amplify';
import { UpdateContactRequest } from '../data_types/update-contact-request';

const updateContact = async (contactId: string, request: UpdateContactRequest): Promise<void> => {
    const user = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    const token = currentSession.getAccessToken().getJwtToken();

    return API.post('membership', `/contact/${contactId}`, {
        body: {
            ...request,
        },
        headers: {
            Authorization: token,
        },
    });
};

export default updateContact;
