import React from 'react';
import { CircularProgress as MuiCircularProgress, withStyles } from '@material-ui/core';

export interface WithLoadingProps {
    loading?: boolean;
    children?: React.ReactNode;
}

const CircularProgress = withStyles({
    root: {
        position: 'absolute',
    },
})(MuiCircularProgress);

// eslint-disable-next-line react/display-name
const WithInsideLoading = <P extends object>(Component: React.ComponentType<P>): React.FC<P & WithLoadingProps> => ({
    loading,
    ...props
}: WithLoadingProps): JSX.Element => {
    return loading ? (
        <Component {...(props as P)}>
            {props.children}
            <CircularProgress size={20} data-testid="spinner" />
        </Component>
    ) : (
        <Component {...(props as P)} />
    );
};

export default WithInsideLoading;
