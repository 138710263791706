import React from 'react';
import { UseFormMethods, useWatch } from 'react-hook-form';
import { PackageItemsEntity } from '../../../../data_types/member-order';
import { INSTALLMENT_ADMINISTRATION_FEE_NAME } from '../../../../utils/constants';
import TitledTable from '../../../Common/TitledTable/TitledTable';
import OrderAdditionalFeesTableItem from './Item/Item';

interface OrderAdditionalFeesTableProps {
    optionalProducts?: PackageItemsEntity[] | null;
    defaultSubscriptionPlanId?: string;
    formMethods?: UseFormMethods;
}

const OrderAdditionalFeesTable = (props: OrderAdditionalFeesTableProps): JSX.Element | null => {
    const subscriptionPlanId = useWatch({
        control: props.formMethods?.control,
        name: 'subscriptionPlanId',
        defaultValue: props.defaultSubscriptionPlanId,
    });

    const optionalProducts = props.optionalProducts
        ?.filter((item: PackageItemsEntity) =>
            item.OrderApi__Display_Name__c.startsWith(INSTALLMENT_ADMINISTRATION_FEE_NAME),
        )
        .map((item: PackageItemsEntity) => {
            return {
                id: item.Id,
                key: item.Id,
                name: item.OrderApi__Display_Name__c,
                price: item.OrderApi__Price__c,
            };
        });

    const show =
        subscriptionPlanId !== props.defaultSubscriptionPlanId && optionalProducts && optionalProducts.length > 0;

    return show && optionalProducts ? (
        <TitledTable title="Additional fees:" items={optionalProducts}>
            <OrderAdditionalFeesTableItem />
        </TitledTable>
    ) : null;
};

export default OrderAdditionalFeesTable;
