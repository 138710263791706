import { API } from 'aws-amplify';
import { GlobalValue } from '../data_types/global-value';

export type EntityType = 'countries' | 'career-types';

const getEntities = async (entity: EntityType): Promise<GlobalValue[]> => {
    const response = await API.get('membership', `/${entity}`, {});

    return response.data;
};

export default getEntities;
