const countries = {
    ChinaShanghai: {
        value: 'China (Shanghai)',
        text: 'Select this option if you live or work in Shanghai.',
    },
    ChinaOthers: {
        value: 'China (Others)',
        text:
            'Select this option if you live or work in China, excluding Shanghai. If you live or work in Hong Kong, please make that selection.',
    },
    HongKong: {
        value: 'Hong Kong',
        text:
            'Select this option if you live or work in China, excluding Shanghai (select Shanghai option) and the Greater Bay Area of Hong Kong, including Hong Kong SAR, Macao SAR, Guangzhou, Shenzhen, Zhuhai, Foshan, Huizhou, Dongguan, Zhongshan, Jiangmen, or Zhaoqing, (select Hong Kong option).',
    },
};

export default countries;
