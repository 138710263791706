import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BackgroundImage from '../BackgroundImage/BackgroundImage';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { useQueryParam } from 'utils/use-query-param';

interface MainLayoutProps {
    children: JSX.Element | JSX.Element[];
}

const MainLayout = (props: MainLayoutProps): JSX.Element => {
    const location = useLocation();
    const [landing, setLanding] = useState(false);

    const aiauParam = JSON.parse(useQueryParam('aiau') || 'false');
    const archiPACParam = JSON.parse(useQueryParam('archiPAC') || 'false');

    useEffect(() => {
        if (aiauParam) {
            sessionStorage.setItem('isAiauPrechecked', aiauParam);
        }
        if (archiPACParam) {
            sessionStorage.setItem('isArchiPACPrechecked', archiPACParam);
        }
    }, [aiauParam, archiPACParam]);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(() => {
                setLanding(false);
            })
            .catch(() => {
                setLanding(location.pathname === '/');
            });
    }, [location]);

    return (
        <>
            {landing ? (
                <BackgroundImage>
                    <Header />
                    {props.children}
                </BackgroundImage>
            ) : (
                <div>
                    <Header />
                    {props.children}
                    <Footer />
                </div>
            )}
        </>
    );
};

export default MainLayout;
