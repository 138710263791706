import { useContext, useState } from 'react';
import { MembershipContext } from '../contexts/MembershipContext';
import { MemberOrderResponse } from '../data_types/member-order';
import getMembershipInfo from '../utils/get-membership-info';

export interface MembershipData {
    loading: boolean;
    error: boolean;
    fetchData: () => Promise<void>;
    membershipInfo: MemberOrderResponse | null;
}

export const useMembershipInfo = (): MembershipData => {
    const { membershipInfo, setMembershipInfo } = useContext(MembershipContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const fetchData = async (): Promise<void> => {
        setLoading(true);
        try {
            const membershipInfoResponse = await getMembershipInfo();
            if (membershipInfoResponse) {
                setMembershipInfo(membershipInfoResponse);
            } else {
                throw new Error('Invalid data');
            }
        } catch (err) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    return { loading, error, fetchData, membershipInfo };
};
