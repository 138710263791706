import { API, Auth } from 'aws-amplify';
import { LineItem, MemberOrderResponse, OrderDues, UpdateMembershipInfoRequest } from '../data_types/member-order';
import { calculateDuesTotal } from './calculate-dues-total';

const calculateOrderTotal = (dues: OrderDues | undefined, request: UpdateMembershipInfoRequest): number => {
    const duesTotal = calculateDuesTotal(dues);
    const lineItemsTotal = request.lineItems.reduce(
        (a: number, b: LineItem) => a + (b.OrderApi__Sale_Price__c || 0),
        0,
    );

    return duesTotal + lineItemsTotal;
};

const sendPayload = async (
    submitAction: string,
    membershipInfo: MemberOrderResponse,
    request: UpdateMembershipInfoRequest,
): Promise<void> => {
    const user = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    const token = currentSession.getAccessToken().getJwtToken();

    return API.post('membership', '/membership-info', {
        body: {
            ...request,
        },
        headers: {
            Authorization: token,
        },
    });
};

export default sendPayload;
