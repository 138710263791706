import { makeStyles } from '@material-ui/core';

export const RadioStyle = makeStyles((theme) => ({
    root: {
        '&$checked': {
            color: theme.palette.error.main,
        },
    },
    checked: {},
}));

export const FormControlLabelStyle = makeStyles((theme) => ({
    label: theme.typography.h5,
}));
