import { Chapters } from '../data_types/member-order';

export const chargesSupplementalDues = (
    chapters: Chapters | undefined,
    emeritus: boolean | undefined,
): boolean => {
    return !!(
        chapters &&
        !emeritus &&
        (chapters.State?.AIA_Charge_Supplemental_dues__c || chapters.Local?.AIA_Charge_Supplemental_dues__c)
    );
};
