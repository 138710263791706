import { makeStyles } from '@material-ui/core';

export const StepperIconStyle = makeStyles((theme) => ({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 25,
        height: 25,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundColor: theme.palette.primary.main,
    },
    completed: {
        backgroundColor: theme.palette.primary.main,
    },
}));

export const StepperStyle = makeStyles({
    horizontal: {
        padding: 0,
        marginBottom: '24px',
        '& :nth-child(1)': {
            '& span': {
                alignItems: 'flex-start',
            },
        },
        '& :nth-child(2)': {
            '& .MuiStepConnector-root': {
                left: 'calc(-50% + -5px)',
                right: 'calc(50% + 20px)',
            },
        },
        '& :nth-child(3)': {
            '& .MuiStepConnector-root': {
                left: 'calc(-50% + 20px)',
                right: 'calc(50% - 5px)',
            },
            '& span': {
                alignItems: 'flex-end',
            },
        },
    },
});
