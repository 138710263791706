import React from 'react';
import { Button, ButtonProps, makeStyles, Typography } from '@material-ui/core';

export interface PlainButtonProps extends ButtonProps {
    text: string;
}

const buttonClasses = makeStyles(({ breakpoints, palette }) => ({
    root: {
        borderRadius: 0,
        textTransform: 'none',
        [breakpoints.down('xs')]: {
            width: '100%',
        },
        backgroundColor: '#fff',
        color: palette.primary.main,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        '&:hover': {
            backgroundColor: 'black',
            color: '#fff',
        },
    },
}));

const PlainButton = (props: PlainButtonProps): JSX.Element => {
    const classes = buttonClasses();

    return (
        <Button
            size="large"
            variant={props.variant || 'contained'}
            className={`${classes.root} ${props.className}`}
            onClick={props.onClick}
            disabled={props.disabled}
            disableElevation
        >
            {props.children}
            <Typography>{props.text}</Typography>
        </Button>
    );
};

export default PlainButton;
