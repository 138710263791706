import { makeStyles } from '@material-ui/core';

export const OrderAdditionalFeesTableItemCellStyle = makeStyles({
    root: {
        padding: '5px 16px',
        height: '43.5px',
        '&:last-of-type': {
            textAlign: 'right',
        },
    },
});
