import { makeStyles } from '@material-ui/core';
import theme from '../utils/theme';

export const StepperStyle = makeStyles({
    root: {
        paddingTop: '15px',
    },
});

export const StepperLabelStyle = makeStyles({
    labelContainer: { marginLeft: '25px' },
    label: {
        color: theme.palette.primary.main + '!important',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

export const StepperContentStyle = makeStyles({
    transition: { marginLeft: '25px' },
});

export const StepperIconStyle = makeStyles((theme) => ({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 25,
        height: 25,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundColor: theme.palette.primary.main,
    },
    completed: {
        backgroundColor: theme.palette.primary.main,
    },
}));
