import { makeStyles } from '@material-ui/core';

export const LandingBoxStyle = makeStyles(({ palette }) => ({
    root: {
        width: 'auto',
        '& .MuiPaper-root': {
            borderBottom: 'none',
        },
        '& button.action-button': {
            margin: '15px 0 5px 0',
        },
        zIndex: 2,
    },
    footer: {
        padding: '15px',
        textAlign: 'center',
        backgroundColor: palette.secondary.main,
        width: '400px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderTop: 'none',
    },
}));
