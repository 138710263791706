import { useContext, useState } from 'react';
import { MembershipContext } from '../contexts/MembershipContext';
import { GlobalValue } from '../data_types/global-value';
import { MEMBERSHIP_COUNTRY_UNITED_STATES } from '../utils/constants';
import getEntities from '../utils/get-entities';

export interface EntitiesData {
    loading: boolean;
    error: boolean;
    fetchData: () => Promise<void>;
    countries: GlobalValue[] | null;
    careerTypes: GlobalValue[] | null;
}

export const useEntities = (): EntitiesData => {
    const { countries, setCountries, careerTypes, setCareerTypes } = useContext(MembershipContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const fetchData = async (): Promise<void> => {
        setLoading(true);
        try {
            const responses = await Promise.all([getEntities('countries'), getEntities('career-types')]);
            if (!responses[0] && !responses[1]) {
                throw new Error('Invalid data');
            }

            const index = responses[0].findIndex((item) => item.value === MEMBERSHIP_COUNTRY_UNITED_STATES);
            if (index) {
                responses[0] = [responses[0][index]].concat(responses[0]);
                responses[0].splice(index + 1, 1);
            }

            setCountries(responses[0]);
            setCareerTypes(responses[1]);
        } catch {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    return { loading, error, fetchData, countries, careerTypes };
};
