import { UNDER_CES_AUDIT, CES_AUDIT_FAILED, CONTACT_STATUS } from './constants';

const eligibleForRestart = (
    membershipStatus: string,
    membershipRenewYear: number | null,
    renewEligibleYears: number[],
): boolean => {
    return (
        membershipStatus === CONTACT_STATUS.TERMINATED &&
        (!membershipRenewYear || !renewEligibleYears.includes(membershipRenewYear))
    );
};

const eligibleForRenew = (
    membershipStatus: string,
    membershipRenewYear: number | null,
    renewEligibleYears: number[],
): boolean => {
    return (
        [CONTACT_STATUS.ACTIVE, CONTACT_STATUS.LAPSED].includes(membershipStatus) &&
        !!membershipRenewYear &&
        renewEligibleYears.includes(membershipRenewYear)
    );
};

const eligibleForActive = (
    membershipStatus: string,
    membershipRenewYear: number | null,
    renewEligibleYears: number[],
): boolean => {
    return (
        membershipStatus === CONTACT_STATUS.ACTIVE &&
        !!membershipRenewYear &&
        membershipRenewYear > Math.max(...renewEligibleYears)
    );
};

type Condition = 'active' | 'join' | 'restart' | 'renew' | 'ces-audit' | 'under-audit' | 'deceased';

export const membershipCondition = (
    membershipStatus: string,
    membershipCesAudit: string | null,
    membershipRenewYear: number | null,
    renewEligibleYears: number[],
    deceased?: boolean,
): Condition => {
    let condition: Condition = 'join';
    if (deceased) {
        condition = 'deceased';
    } else if (eligibleForRestart(membershipStatus, membershipRenewYear, renewEligibleYears)) {
        condition = 'restart';
    } else if (eligibleForRenew(membershipStatus, membershipRenewYear, renewEligibleYears)) {
        condition = 'renew';
        if (membershipCesAudit === CES_AUDIT_FAILED) {
            condition = 'ces-audit';
        }
        if (membershipCesAudit === UNDER_CES_AUDIT) {
            condition = 'under-audit';
        }
    } else if (eligibleForActive(membershipStatus, membershipRenewYear, renewEligibleYears)) {
        condition = 'active';
    }
    return condition;
};
