import { makeStyles } from '@material-ui/core';

export const HeaderStyle = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.dark,
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '40px',
    },
}));

export const BoxStyle = (width?: string): Record<string | number | symbol, string> => {
    return makeStyles({
        root: {
            width: width || '400px',
        },
        borderedBox: {
            borderTop: 'none',
            padding: '25px 60px',
        },
    })();
};
