import { Typography } from '@material-ui/core';
import React from 'react';
import BorderedBox from '../BorderedBox/BorderedBox';
import { BoxStyle, HeaderStyle } from './BoxWithTitle.style';

interface BoxWithTitleProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
    width?: string;
    children: React.ReactNode[] | React.ReactNode;
}

const BoxWithTitle = (props: BoxWithTitleProps): JSX.Element => {
    const headerClasses = HeaderStyle();
    const boxClasses = BoxStyle(props.width);

    return (
        <div className={`${boxClasses.root} ${props.className}`}>
            <div className={headerClasses.root}>
                <Typography variant="h2">{props.title}</Typography>
            </div>
            <BorderedBox className={boxClasses.borderedBox}>{props.children}</BorderedBox>
        </div>
    );
};

export default BoxWithTitle;
