import { makeStyles } from '@material-ui/core';

export const GridStyle = makeStyles({
    root: {
        '& .full-width': {
            width: '100%',
            '& p': {
                textAlign: 'center',
            },
            '& button': {
                width: '100%',
            },
        },
    },
});
