import { CustomFormOptions, FormOptions } from '../data_types/form-options';

export interface ContactFormDefaultValues {
    [key: string]: string | boolean | undefined;
    selfDescribe: string;
    country: string;
    careerType: string;
    zipCode: string;
    gradDate: string;
    licenseDate: string;
    membershipStatus?: string;
}

export const generateContactFormOptions = (options: CustomFormOptions<ContactFormDefaultValues>): FormOptions => {
    const defaultFormOptions = {
        resolver: options.resolver,
        defaultValues: {
            ...{
                selfDescribe: '',
                country: '',
                careerType: '',
                zipCode: '',
                gradDate: '',
                licenseDate: '',
                membershipStatus: 'Non-Member',
            },
            ...options.defaultValues,
        },
        shouldFocusError: false,
    };
    return defaultFormOptions as FormOptions;
};
