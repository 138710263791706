import { PackageItemsEntity } from '../data_types/member-order';

export const filterByVisibleOptionalProducts = (
    items?: PackageItemsEntity[] | null,
): PackageItemsEntity[] | undefined => {
    return items?.filter(
        (item: PackageItemsEntity) =>
            !item.OrderApi__Display_Name__c.startsWith('Installment') &&
            !item.OrderApi__Display_Name__c.startsWith('Supplemental Dues -'),
    );
};
